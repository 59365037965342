/* DEFINE BREAKPOINTS FOR RESPONSIVE DESIGN
	------------------------------------------------- */
@mixin bp($point) {
	@if $point == very-large {
		@media (min-width: 1850px) { @content }
	}
	@else if $point == x-large {
		@media (min-width: 1400px) { @content; }
	}
	@else if $point == large {
		@media (min-width: 1200px) { @content; }
	}
	@else if $point == medium {
		@media (min-width: 991px)  { @content; }
	}
	@else if $point == tablet {
		@media (min-width: 845px) { @content }
	}
	@else if $point == small {
		@media (min-width: 452px) { @content }
	}
	@else if $point == sosmall {
		@media (min-width: 335px) { @content }
	}
	@else {
		@media (min-width: $point)  { @content; }
	}
}

@mixin phone($phone) {
	@if $phone == ipx {
		@media only screen 
			and (device-width : 375px) 
			and (device-height : 812px) 
			and (-webkit-device-pixel-ratio : 3) { @content; }
	}
	@else if $phone == ip {
		@media only screen
			and (device-width : 375px)
			and (device-height : 667px)
			and (-webkit-device-pixel-ratio : 2) { @content; }
	}
	@else if $phone == ipplus {
		@media only screen
			and (device-width : 414px)
			and (device-height : 736px)
			and (-webkit-device-pixel-ratio : 3) { @content; }
	}
	@else {
		@content;
	}
}
