/* Define breakpoint values for the dynamic sizing */
$grid--items-2: 54rem;
$grid--items-3: 75rem;
$grid--items-4: 85rem;
$grid--items-5: 100rem;

.block-wrapper {
	margin-right: -10px;
	margin-left: -10px;

	position: relative;
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;

	a {
		color: black;
	}

	&.cols-1 {
		.block-container {
			flex: 100%;
			max-width: calc(100% - 20px);
		}
	}

	&.cols-2 {
		.block-container {
			flex: 100%;
			max-width: calc( 100% );

			@include bp($grid--items-2) {
				flex: 50%;
				max-width: calc( 50% - 30px );
			}
		}
	}

	&.cols-3 {
		.block-container {
			flex: 100%;
			max-width: calc(100% - 20px);

			@include bp($grid--items-2) {
				flex: 50%;
				max-width: calc( 50% - 20px );
			}

			@include bp($grid--items-3) {
				flex: 33%;
				max-width: calc( 33% - 20px );
			}
		}
	}

	&.cols-4 {
		.block-container {
			flex: 100%;
			max-width: calc( 100% - 30px );

			@include bp($grid--items-2) {
				flex: 50%;
				max-width: calc( 50% - 30px );
			}

			@include bp($grid--items-3) {
				flex: 33%;
				max-width: calc( 33% - 27px );
			}

			@include bp($grid--items-4) {
				flex: 25%;
				max-width: calc( 25% - 30px );
			}
		}
	}

	&.cols-5 {
		.block-container {
			flex: 100%;
			max-width: calc( 100% - 30px );

			@include bp($grid--items-2) {
				flex: 50%;
				max-width: calc( 50% - 30px );
			}

			@include bp($grid--items-3) {
				flex: 33%;
				max-width: calc( 33% - 27px );
			}

			@include bp($grid--items-4) {
				flex: 25%;
				max-width: calc( 25% - 30px );
			}

			@include bp($grid--items-5) {
				flex: calc( 100%/5 );
				max-width: calc( 100%/5 - 30px );
			}
		}
	}
}

.block-container {
	background-color: white;
	box-shadow: 0 3px 7px rgba(0, 0, 0, 0.05);
	border-radius: 9px;
	border:1px solid #DBDBDB;
	font-size: $meta-text;
	margin: 10px;
	transition: $animation-cubic-short !important;

	a {
		text-decoration: none;
	}

	.block-image {
		min-height: 315px;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		padding-bottom:0;
	}

	.block-header {
		padding: 1.25em 1.45rem;

		time {
			color: #535353;
			font-size: 0.9em;
		}
		
		h3 {
			display: block !important;
			font-size: 1rem !important;
			margin-bottom: 0px !important;
			margin-top: 0px !important;
			font-weight: bold !important;
		}
	}

	.block-time {
		color: #515151;
		padding: $block-padding-size;
		padding-top:1em;
		padding-bottom:0;
	}

	.block-content {
		padding: $block-padding-size;
		clear:both;
		display:block;

		h3 {
			font-weight: 400;
			font-size: 1.25em;
			padding:0;
			margin-top: 0;
			margin-bottom: .25em;
		}
	}

	.block-footer {
		padding: 0.8rem 1.25rem;
	}

	&:hover {
		box-shadow: 0 3px 15px rgba(0, 0, 0, 0.10);
	}

	.expandable-container {
		.expandable-header {
			border-right: 0px !important;
			border-bottom: 0px !important;
			box-shadow: none !important;
			border-radius: 0px !important;
		}

		.expandable-content {
			width: 100%;
		}
	}
}
