.feedback-block {
	text-decoration: none;
	display: block;
	margin-bottom: 1em;
	background-color: white;
	padding: 1em;
	border-radius: 0.25em;
	width: 100%;

	border-radius: 3px;

	div {
		color: #363636;
		height: 22px;
		line-height: 15px;
		letter-spacing: 1px;
		font-size: 0.6em;
		text-transform: uppercase;
		margin-right: 0.6em;
	}

	span {
		color: #363636;
	}

	@include bp(tablet) {
		border-radius: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

.container.progress {
	.matrix-block-container {
		font-size: 0.9rem;
	}
}
