$dot-size: 20px;

.dot {
	float: left;
	line-height: $dot-size;

	.dot-content {
		width: $dot-size;
		height: $dot-size;
		border-radius: $dot-size;
		cursor: pointer;
		float: left;

		background-color: #ccc;
		display: inline-block;
	}

	.text {
		display: none;
		text-overflow: ellipsis;
		float: left;
	}
}

.dots-container {
	position: relative;
	width: 100%;

	&.collapse {
		.dot {
			margin-left: -7px;

			&:first-child {
				margin-left: 0;
			}
		}
	}

	&.show-content {
		.dot {
			margin-left: 0.55rem;

			.dot-content {
				margin-right: 0.15rem;
			}

			&:first-child {
				margin-left: 0;
			}
		}
	}

	&.show-content {
		.text {
			display: block;
		}

		/*
		.dot-content {
			&::before,
			&::after {
				display: none !important;
			}
		}
		*/
	}
}