$dot-size: 20px;

.dot {
	float: left;
	line-height: $dot-size;
	display: inline-flex;
	align-items: center;

	.dot-content {
		width: $dot-size;
		height: $dot-size;
		border-radius: $dot-size;
		cursor: pointer;
		flex-shrink: 0;
		float: left;
		background-color: #ccc;
		display: block;
	}

	span.icon {
		width: $dot-size !important;
		height: $dot-size !important;
		margin-right: 0.43rem;
	}

	.text {
		display: none;
		text-overflow: ellipsis;
		float: left;
	}
}

.dots-container {
	position: relative;
	width: 100%;

	&.collapse {
		.dot {
			margin-left: -7px;
			border: 2px solid white;

			&:first-child {
				margin-left: 0;
				border: 2px solid transparent;
			}
		}
	}

	&.show-content {
		.dot {
			margin-left: 0.55rem;

			.dot-content {
				margin-right: 0.15rem;
			}

			&:first-child {
				margin-left: 0;
			}
		}
	}

	&.show-content {
		.text {
			display: block;
		}

		/*
		.dot-content {
			&::before,
			&::after {
				display: none !important;
			}
		}
		*/
	}

	&.inline {
		display: inline-block;
		width: auto;
	}
}