.user-assessments {
	.block {
		padding: 0;
		padding-top: 1.4rem;
		position: relative;

		.flex {
			padding: 0 1.7rem;
			margin-bottom: 1.4rem;

			span.title {
				margin-bottom: 0;
			}
		}
	}

	.c--expandable {
		span.title {
			margin-bottom: 0;
		}

		.inner-header {
			border-radius: 3px;
		}
	}

	.course-content {
		.c--expandable {
			margin-top: 1.25rem;

			&:first-child {
				margin-top: 0;
			}
		}

		.c--expandable .h-content {
			background-color: white;
		}

		.collapsible-trigger {
			font-weight: 500;
		}
	}

	.bar-outer-container {
		display: block !important;
		grid-template-columns: 2fr 5fr;
		margin-right: 1.5rem;

		.bar-title-container {
			white-space: nowrap;

		}

		.graph {
			font-size: var(--size-14);
		}

		@media (min-width: 1024px) {
			display: grid !important;
		}
	}

	.assessment-goals-dots {
		display: flex;
		align-items: center;

		.goal-dot {
			width: 11px;
			height: 11px;
			background-color: #F5F5F5;
			border-radius: 6px;
			margin-right: 0.4rem;

			&:last-child {
				margin-right: 0;
			}
		}
	}

	.display-matrix {
		.row {
			padding-bottom: 1.25rem;
			border-bottom: 2px solid #e4e4eb;

			&:last-of-type {
				border-bottom: 0;
			}
		}

		.paragraph {
			border-bottom: 2px solid #e4e4eb;

			&:last-child {
				border-bottom: 0;
			}
		}
	}

	.expand-all {
		display: flex;
		align-items: center;
		cursor: pointer;

		span.icon[name="ArrowLeft"] {
			height: 1.25rem;
			margin-right: .35rem;

			svg {
				transition: all 0.24s cubic-bezier(0.215, 0.61, 0.355, 1);
				transform: rotate(90deg);
			}
		}

		&.active {
			svg {
				transform: rotate(-90deg) !important;
			}
		}
	}

	span.title {
		&:after {
			content: '';
			background-color: var(--color);
			height: 4.35rem;
			width: 4px;
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			border-top-left-radius: 3px;
			border-bottom-left-radius: 3px;
			z-index: 0;
		}
	}

}