.skeleton {
	display: block;
	width: 100%;
	height: 1em;
	background: #f1f1f1;

	animation-duration: .6s;
	animation-name: blinkFade;
	animation-iteration-count: infinite;
	animation-direction: alternate;
}

@keyframes blinkFade {
	from {
		opacity: 1;
	}
	to {
		opacity: .4;
	}
}
