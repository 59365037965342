.navbar {
	display: block;
	background: #fff;
	box-shadow:0px 0px 5px rgba(0,0,0,0.07);
	text-align: center;
	position: fixed;
	position: static;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 9999;
	padding-top: 0.55rem;

	@include bp(tablet) {
		padding-top: 0;
	}
}

.navbar-item {
	font-size: .825em;
	color: inherit;
	text-decoration: none;
	padding: 0.55rem 0.75rem;
	display: inline-block;
	cursor: pointer;
	margin-bottom: 0.55rem;
	margin-right: 0.65rem;

	background-color: #F6F6F6;
	border-radius: $border-radius;

	@include bp(tablet) {
		padding: 1.3rem 1.4rem;
		background-color: transparent;
		border-radius: 0;
		margin: 0;
	}
}
