.single-task {
	.matrix-knowledge-req-title {
		margin-top: 2em !important;
		font-size: 1.6em;
		margin: .75em 0;
		text-transform: uppercase;
		font-size: .7em;
		font-weight: 500;
		color: #5d5d5d;
		letter-spacing: 1px;
		margin: 0;
		padding-bottom: 1.75em;
	}

	.matrix-clarification-title {
		font-size: 1.6em;
		margin: .75em 0;
		text-transform: uppercase;
		font-size: .7em;
		font-weight: 500;
		color: #5d5d5d;
		letter-spacing: 1px;
		margin: 0;
		padding-bottom: 1.75em;
	}

	.single-assignment-meta {
		margin-bottom: 0.5em;

		span {
			font-weight: 500;
			font-size: 18px;
		}
	}

	.post-content {
		color: #3a3a3a;
		word-break: normal !important;

		img {
			max-width: 100%;
		}
	}

	.single-section {
		padding-top: 1em;
		margin-bottom: 1.5em;

		h3 {
			font-size: 1.6em;
			margin-top: 1rem;
			margin-bottom: 1.25rem;
		}

		h4 {
			margin-top: 1.75em;
			margin-bottom: 0.75em;
		}

		&.status-section {
			margin-bottom: 3em;
		}

		&.left-side {
			width: 100%;
			display: block;
			float: none;

			@include bp(tablet) {
				width: 70%;
				float: left;
			}
		}

		.list-outer-container {
			width: 100% !important;
			margin: 0 !important;
			float: none !important;
			margin-bottom: 2em !important;

			.list-container {
				padding: 0 !important;
				border: 0 !important;
			}

			.list-title-container {
				background-color: white;
				padding: 1.35em;
				border-radius: 4px;
				box-shadow: -10px 0px 35px rgba(0, 0, 0, 0.09);
				position: relative;
				z-index: 10;
			}

			.list-item {
				width: 100% !important;
				float: none !important;
				margin: 0 !important;
				border: 0 !important;
				background-color: white !important;
				padding: 1.35em !important;
				padding-left: 2em !important;
				transition: background-color $animation-cubic-short !important;

				&:hover {
					background-color: darken(#fff, 3) !important;
				}
			}

			.drag-area {
				float: right;
				line-height: 47px;
				margin-left: 2.25em;
			}

			.status-switcher {
				display: inline-block;
				margin-left: 5em;

				select {
					padding-right: 5em;
				}
			}

			.workspace {
				float: right;
				line-height: 47px;

				a {
					color: black;

					&:hover {
						text-decoration: underline;
					}
				}
			}

			.item-name {
				width: 29%;
				display: inline-block;
			}

			input[type=text] {
				padding: 0.4em;
				border: 0;
				background-color: white;
				font-size: 1.15em;
				width: 50%;
				padding-left: 0;
			}
		}

		&:first-child {
			padding-top: 0;
		}

	}

	.single-actions {
		a {
			margin-bottom: 0.75em;
			width: 100%;
			display: block;
		}

		h3 {
			margin-bottom: 0.75rem;
		}

		.action-section {
			margin-bottom: 1rem;
		}

		&.right-side {
			width: 100%;
			display: block;
			float: none;

			@include bp(tablet) {
				width: 25%;
				float: right;
			}
		}
	}

	.single-dot {
		&:before {
			content: '';
			width: 10px;
			height: 10px;
			border-radius: 10px;
			display: inline-block;
			position: relative;
			margin-right: 8px;
			top: -1px;
		}

		&.dot-green {
			&:before {
				background-color: #73D266;
			}
		}

		&.dot-yellow {
			&:before {
				background-color: #EFC24C;
			}
		}

		&.dot-orange {
			&:before {
				background-color: #FDC458;
			}
		}

		&.dot-red {
			&:before {
				background-color: #FF4D4D;
			}
		}

		&.dot-gray {
			&:before {
				background-color: #E4E4EB;
			}
		}
	}

	.single-section-divider {
		width: 100%;
		background-color: #E4E4EB;
		height: 1px;
		display: block;
		clear: both;
		margin-top: 3em;
	}

	.dropzone {
		margin-top: 0.5em;
		background-color: white;

		.upload-type {
			&:last-child {
				border-right: 0;
			}
		}
	}

	.edit-link {
		cursor: pointer;

		a {
			color: $color-meta-text;
			font-size: 0.8em;
		}
	}

	.button-list {
		border: 1px solid #EEEEEE;
		background: white;
		padding: 1em 0;
	}

	.first-paragraph-creation {
		border-top: 0 !important;
	}

	.single-assignment-message {
		padding: 2rem;
		background-color: white;
		border-radius: 8px;

		.message-form {
			padding: 0;
		}

		>span {
			margin-bottom: 0.5rem;
			display: block;
		}
	}

}

.modal-container {
	.single-assignment-message {
		padding-top: 1rem;
		padding-bottom: 1rem;

		h3 {
			margin: 0 2rem;
			margin-bottom: 1rem;
		}

		.message-form {
			padding-bottom: 0;
			padding-top: 1rem;
		}

		.task-conversation {
			padding: 1rem 0;
		}

		.conversation-begin {
			color: #898989;
			text-transform: uppercase;
			padding: 0.6rem 0;
			text-align: center;
			width: 100%;
			font-size: .85rem;
			height: 100%;
		}

		>span {
			margin-bottom: 0.5rem;
			display: block;
		}
	}
}

td.status {
	min-width: 200px;
}