.student-assessment-list {
	background:white;
	margin:0;
	margin-top:1em;
	border-spacing:0;
	border:1px solid #EEEEEE;
	border-radius:3px;

	border-bottom: none;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;

	thead {
		tr {
			padding:0.5em 0;
		}

		th {
			border-bottom: 1px solid #F4F4F7;
			padding: 1.5em;
			text-transform: uppercase;
			color: #3A3A3A;
			font-size:0.7em;
			letter-spacing: 1px;
			text-align: left;
			font-weight: normal;
		}
	}

	tbody {
		tr {
			&:hover {
				background-color:#FAFAFA;

				td {
					.hidden-hover {
						display:inline-block;
					}
				}	
			}

			td {
				padding:1em 1.5em;

				.hidden-hover {
					display:none;
				}

				&.name {
					font-weight: 500;
					font-size: 1em;
				}

				&.make_assessment {
					width:20%;

					&.overview {
						text-transform: uppercase;
						color: #3A3A3A;
						font-size:0.75em;
						letter-spacing: 1px;
					}
				}

				&.assessment-bar {
					padding: 0;
					width: 45%;
					.bar-container {
						width:100%;
					}
					.assessment-bar-container {
						width: 100%;
						display: flex;
					}

					.step {
						display:inline-block;
						color:white;
						padding:0.5em 0;
						padding-right:23px;
						padding-left:7px;

						&.step-F {
							background-color:$color-grade-f;
						}

						&.step-E {
							background-color:$color-grade-e;
						}

						&.step-C {
							background-color:$color-grade-c;
						}

						&.step-A {
							background-color:$color-grade-a;
						}

						&:first-child {
							border-top-left-radius:20px;
							border-bottom-left-radius:20px;
						}

						&:last-child {
							border-top-right-radius: 20px;
							border-bottom-right-radius: 20px;
						}
					}
				}

			}

			&.no-hover {
				&:hover {
					background-color:white;
				}
			}
		}

		.select {
			float:left;
			margin-right:1em; 

			select {
				float: left;
				padding: 0.7em;
				padding-right: 5em;
			}
		}
	}

	td {
		font-size: 0.9em;
	}

}