$animation-long: 0.5s cubic-bezier(0.23, 1, 0.32, 1);
$animation-short: 0.24s cubic-bezier(0.215, 0.61, 0.355, 1);

.graph {
	&.progress {
		transition: $animation-long;
		position:relative;
		z-index:3;
		border:1px solid #9E82EB;
		border-radius:15px;
		height:23px;
		width:100%;

		.progress-bar {
			position:absolute;
			top:2px;
			left:3px;
			background-color: #9E82EB;
			border-radius:15px;
			height:17px;
			max-width: calc( 100% - 5px );
			transition: $animation-long;
		}
	}

	&.bar {
		padding: 0;
		width: 100%;

		.bar-container {
			width:100%;
			display: flex;

			&:first-child {
				.step {
					&:first-child {
						border-top-left-radius:20px;
					}

					&:last-child {
						border-top-right-radius:20px;
					}
				}
			}

			&:last-child {
				.step {
					&:first-child {
						border-bottom-left-radius:20px;
					}

					&:last-child {
						border-bottom-right-radius:20px;
					}
				}
			}
		}

		.step {
			display:inline-block;
			color:white;
			padding:0.5em 0;
			padding-right: 23px;
			padding-left: 7px;
			transition: $animation-short;
			text-align:center;
			font-weight:400;
			font-size:0.8em;
			min-width: 15px;
		}
	}

	&.standing-bar {
		.bar-container {
			display: flex;
			align-items: flex-end;

			.bar {
				display: flex;
				margin-left: 1.105rem;
				flex: 1;
				flex-direction: column;
				color: #0F0F0F;
				align-content: center;
				align-items: center;
				justify-content: center;
				transition: all $animation-long;

				.amount {
					flex: 1;
					margin-bottom: 0.55rem;
				}

				.color {
					transition: all $animation-long;
					width: 100%;
					border-radius: 5px;
				}

				&:first-child {
					margin-left: 0;
				}
			}
		}
    }
    
    &.standing-bar {
		.bar-container {
			display: flex;
			align-items: flex-end;

			.bar {
				display: flex;
				margin-left: 1.105rem;
				flex: 1;
				flex-direction: column;
				color: #0F0F0F;
				align-content: center;
				align-items: center;
				justify-content: center;
				transition: all $animation-long;

				.amount {
					flex: 1;
					margin-bottom: 0.55rem;
				}

				.color {
					transition: all $animation-long;
					width: 100%;
					border-radius: 5px;
				}

				&:first-child {
					margin-left: 0;
				}
			}
		}
	}

}
