.core-content {
	.aspects {
		.aspect-row {
			width: 100%;
			margin: 0.5rem 0;

			.checkbox {
				cursor: pointer !important;

				label {
					cursor: pointer !important;
				}
			}
		}

	}

	&.selected {
		.content-row {
			h4 {
				margin: 0.5em 0;
			}
		}
	}
}

