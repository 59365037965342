.news {
	h3 {
		display: inline-block;
		font-size: 1.1em;
		font-weight: 500;
	}
}

.news_link a {
	text-decoration: none;
}

.card.card-news {
	overflow: hidden;
	height: 12.0625rem;
}
