.file-list {
	position: relative;
	z-index: 999999999;
	.list-directory, .list-file {
		padding:0.65rem 1.05rem;
		background-color: white;
		border-radius: 4px;
		margin-bottom:0.65rem;
		cursor:pointer;
		transition: 0.24s cubic-bezier(0.215, 0.61, 0.355, 1);

		.icon-container, .checkbox-container {
			float: left;
			width: 26px;
			height: 26px;
			text-align: center;
			margin: 0 0.4rem;

			svg {
				height:100%;
			}
		}

		.checkbox-container {
			display:none;

			.psuedo {
				height: 26px;
				width: 26px;

				&.active {
					.icon {
						transform: scale(1.05);
					}
				}
			}
		}

		.info {
			float:left;
			width: calc(100% - 46px - 2rem);
			padding-top: 2px;
		}
		
		&.active {
			.icon-container {
				display:none;
			}

			.checkbox-container {
				display: block;
			}
		}

		&:hover {
			box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);

			.checkbox-container {
				display:block;
			}

			.icon-container {
				display:none;
			}
		}
	}

	.list-file {
		padding-left:0;
	}
}
