.page.absence {
	.indicator {
		display: inline-block;
		margin-right: 0.25rem;
		width: 15px;
		height: 15px;
		border-radius: 3px;
	}

	.new-card {
		.column {
			flex: 0;
			margin-right: 1.25rem;

			strong {
				display: block;
				margin-top: 0.65rem;
				font-weight: 500;
				font-size: var(--size-14);
				margin-bottom: 0.2rem;
			}

			&.space-placeholder {
				margin-top: 30px;
				flex: 1;

				@media (min-width: 991px) {
					margin-top: 0;
				}
			}

			&:last-child {
				margin-right: 0;
			}
		}

		.expandable-container {
			.expandable-header {
				border: 0;
				background-color: transparent;
				position: static;

				.title {
					display: none;
				}

				.trigger-container {
					padding: 0;
					height: 30px;
					width: 30px;
					position: absolute;
					top: 1.75rem;
					left: 1.75rem;
					z-index: 9;
				}
			}

			.expandable-content {
				padding-left: 0 !important;
				padding-right: 0 !important;
				border: 0;
				border-bottom: 0 !important;
				border-radius: 0;
				background-color: transparent;
			}
		}

		@media (min-width: 991px) {
			.column {
				strong {
					margin-top: 0;
				}
			}
		}
	}

	.lessons {
		border-top: 1px solid #ccc;
		padding-top: 1rem;
		margin-top: 1rem;
	}

	.lesson-item {
		margin-top: 0.45rem;
		margin-bottom: 1rem;

		.layout.flex {
			align-items: flex-start;
		}

		.column {
			display: flex;
			align-items: center;
			flex: 1;
			margin-bottom: .15rem;
		}

		@media (min-width: 991px) {
			margin-bottom: 0;

			.layout.flex {
				align-items: center;
			}

			.column {
				margin-bottom: 0;
			}
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	.edit-trigger {
		width: 30px;
		height: 30px;
		border-radius: 15px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		&:hover {
			background-color: #eee;
		}

		svg {
			position: relative;
			top: 2px;
			width: 14px;
			height: 14px;
		}
	}

	.invalid-absent-badge {
		position: relative;
		left: 1.35rem;
		z-index: 10;
		top: -6px;
	}

}