[data-tooltip] {
	position: relative;

	&:before,
	&:after {
		position: absolute;
		left: 50%;
		top: 100%;
		visibility: hidden;
		opacity: 0;
		z-index: 1;
		transform: translate(-50%, 0%);
		transition: opacity .2s ease-in-out, visibility .2s ease-in-out;
	}

	&:before {
		content: "";
		border-color: #323232;
		border-style: none;
		border: 5px solid transparent;
		border-bottom: 5px solid #323232;
		margin-top: 2px;
		left: 50%;
	}

	&:after {
		content: attr(data-tooltip);
		border-radius: 3px;
		margin-top: 12px;
		text-align: left;
		min-width: 11rem;
		width: 100%;
		max-width: 300px;
		padding: 1em;
		font-size: 11px;
		line-height: 1.2;
		background-color: #323232;
		color: #ecf0f1;
		text-align: center;
	}

	&:hover:before,
	&:hover:after,
	&:focus:before,
	&:focus:after {
		opacity: 1;
		visibility: visible;
	}
}

[data-tooltip-visible] {
	position: relative;
	opacity: 1;
	visibility: visible;

	&:before,
	&:after {
		position: absolute;
		left: 50%;
		top: 100%;
		z-index: 1;
		transform: translate(-50%, 0%);;
	}

	&:before {
		content: "";
		border-color: #323232;
		border-style: none;
		border: 5px solid transparent;
		border-bottom: 5px solid #323232;
		margin-top: 2px;
		left: 50%;
	}

	&:after {
		content: attr(data-tooltip-visible);
		border-radius: 3px;
		margin-top: 12px;
		text-align: left;
		min-width: 11rem;
		width: 100%;
		max-width: 300px;
		padding: 1em;
		font-size: 11px;
		line-height: 1.2;
		background-color: #323232;
		color: #ecf0f1;
		text-align: center;
	}
}

[data-tooltip-right] {
	position: relative;

	&:before,
	&:after {
		position: absolute;
		left: 100%;
		visibility: hidden;
		opacity: 0;
		z-index: 1;
		transform: translate(0%, 0%);
		transition: all .2s ease-in-out;
	}

	&:before {
		content: "";
		border-color: #323232;
		border-style: none;
		border: 5px solid transparent;
		border-bottom: 5px solid #323232;
		top: 50%;
		left: 100%;
		transform: translate(-100%, -50%) rotate(-90deg);
	}

	&:after {
		content: attr(data-tooltip-right);
		border-radius: 3px;
		text-align: left;
		min-width: 11rem;
		width: 100%;
		max-width: 300px;
		padding: 1em;
		font-size: 11px;
		line-height: 1.2;
		background-color: #323232;
		color: #ecf0f1;
		text-align: center;
	}

	&:hover:before,
	&:hover:after,
	&:focus:before,
	&:focus:after {
		opacity: 1;
		visibility: visible;
	}
}

[data-tooltip-left] {
	position: relative;

	&:before,
	&:after {
		position: absolute;
		right: 100%;
		visibility: hidden;
		opacity: 0;
		z-index: 1;
		transform: translate(0%, 0%);
		transition: all .2s ease-in-out;
	}

	&:before {
		content: "";
		border-color: #323232;
		border-style: none;
		border: 5px solid transparent;
		border-bottom: 5px solid #323232;
		top: 50%;
		right: 100%;
		transform: translate(100%, -50%) rotate(90deg);
	}

	&:after {
		content: attr(data-tooltip-left);
		border-radius: 3px;
		text-align: left;
		min-width: 11rem;
		width: 100%;
		max-width: 300px;
		padding: 1em;
		font-size: 11px;
		line-height: 1.2;
		background-color: #323232;
		color: #ecf0f1;
		text-align: center;
	}

	&:hover:before,
	&:hover:after,
	&:focus:before,
	&:focus:after {
		opacity: 1;
		visibility: visible;
	}
}