.goal-preview {
	display: inline-block;
	padding: 8px;
	background-color: #3ACCE1;
	margin:4px;
	border-radius: $border-radius;
	color: white;
}

.preschoolgoals-group-divider {
	float: left;
	width: 100%;

	.preschoolgoals-group-title {
		width: 100%;
		color: $color-meta-text;
		text-align: center;
		padding-top:0.8rem;
		padding-bottom:0.8rem;
		text-transform: uppercase;
		font-size: 0.8rem;
		font-weight: 500;
	}

	&:first-child {
		.preschoolgoals-group-title {
			padding-top: 0;
		}
	}
}
