.dynamic-form {

	.placeholder-input {
		background-color: #f9f9f9;
		border-radius: 3px;
		border: 1px solid #dddddd;
		margin-bottom: 0.75rem;
		padding: 15px;
		font-size: 1em;
		font-weight: 400;
		min-height: 60px;
		display: flex;
		align-items: center;

		&.fields_input_textarea {
			display: block;
			min-height: 6rem;
		}
	}

}
