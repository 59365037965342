body.ReactModal__Body--open {
	overflow: hidden;
}

.modal-overlay {
	min-height: 100vh;
	padding: 40px 0;

	@include bp(medium) {
		padding: 40px 20px; 
	}

	.modal-container {
		background-color: white;
		border-radius: 5px;
		padding-bottom: 0.2em;
		max-width: 65em;
		margin: 0 auto;

		// Added to show WYSIWYG tooltip/popup that appears at the edge of modal
		// // (e.g. embed dialog at the start of a line).
		// overflow: visible;

		.modal-header {
			display:block;
			padding: 2.25em 1.25em .75em 1.25em;

			.title {
				float:left;

				font-size: 1.625em;

				> h1, h2, h3, h4, h5, h6 {
					font-size: 1em;
				}
			}

			.close {
				float:right;
				cursor: pointer;

				svg {
					height: 20px;
					width: 20px;
				}
			}

			@include bp( tablet ) {
				padding: 2.25em 2.5em .75em 2.5em;
			}
		}

		.modal-content {
			width:100%;
			margin-top:1em;

			.form-row {
				padding: 1px 1.25em;

				@include bp( tablet ) {
					padding: 1px 2.5em;
				}
			}

			.loading {
				.cards-container {
					margin-left: 0;
					margin-right: 0;
					align-content: center;
					justify-content: center;
				}
			}
		}

		&.prompt {
			display:inline-block;
			position: absolute;
			min-width:auto;
			width: 95%;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -100%);

			.modal-header {
				padding: 1.45rem 2em 0 2em;
			}

			.form-row {
				.button-primary {
					width:auto !important;
					margin-left:0;
					margin-right:0;
				}
			}
			
			.prompt-title {
				clear: both;
				font-size: 1.4em;
				margin-top: .1em;
				margin-bottom: .2em;
				width:100%;
				text-align: center;
			}

			@include bp( medium ) {
				min-width: 35rem;
				width: auto;
			}
		}

		&.file-picker {
			.modal-header {
				padding: 0;
			}
		}

		&.small {
			max-width: 50rem;

			.modal-header {
				padding: 1.75em 1.75em;
			}

			.modal-content {
				margin-bottom: 1.25em;
			}
		}

	}
}
