.select-students,
.select-userinfo,
.select-language {
	.menu-item {

		.flag {
			height: 16px;
			width: 24px;
			object-fit: cover;
			border-radius: 2px;
		}

		.person {
			height: 24px;
			width: 24px;
		}

		.label {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;

			&::after {
				content: url("/src/assets/svg/chevron-down.svg");
				height: 24px;
				width: 24px;
				margin-left: 1rem;
			}
		}
	}

	.cbp-tm-submenu {
		min-width: max-content !important;
		width: 100%!important;
		max-width: calc(max-content + 2rem) !important;
		.cbp-children li>span{
			padding-right: 1.5rem !important;
		}
	}
}

.single-student .menu-item {
	cursor: initial;
	&:hover {
		background-color: transparent;
	}
}