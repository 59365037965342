$icon-size: 17px;
$icon-padding: 1rem;
$input-height: 50px;
$border-radius: 45px;

.search-container {
	position: relative;

	.search-box-container {
		position: relative;
		background-color: #fff;
		border: 1px solid #C9C9C9;
		padding-left: $icon-padding;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: $border-radius;
		overflow: hidden;

		input {
			border: 0px;
			width:100%;
			height: $input-height;
			background-color: transparent;
			font-size:1rem;
		}

		.search-box-icon {
			width: $icon-size;
			height: $icon-size;
			margin-right: $icon-padding;

			.loading-spinner {
				width: $icon-size;
				height: $icon-size;
			}

			svg {
				display: block;
				width: $icon-size;
				height: 100%;
			}
		}
	}
}
