.matrix-block {
	border: $get_the_border;
	border-bottom: 0;

	.matrix-block-container {
		padding: 1.45em 1em 1.45em 1em;
		background-color: white;
		position: relative;
		z-index: 2;
		border-radius: $border-radius;
		border-bottom: $border-width solid $border-color;
		color: #3A3A3A;
		transition: $animation-cubic-short;
		font-size: 0.9em;
		cursor: pointer;
		display: block;
		flex-wrap: nowrap;
		align-content: center;
		height: 100%;

		.drawer-icon {
			position: absolute;
			right: 1em;
			height: auto;
			top: 35px;

			svg {
				transition: all $animation-cubic-short;
			}
		}

		.matrix-block-title {
			font-size: 0.9em;
			font-weight: 400;
			width: 100%;
			display: flex;
			display: -ms-flexbox;
			align-items: center;
			margin-bottom: 1em;

			span {
				margin-left: 0;
			}

			.checkbox {
				float: left;
			}

			@include bp(tablet) {
				position: relative;
				width: 40%;
				padding-right: 3em;
				margin-bottom: 0;

				span {
					margin-left: 1em;
				}
			}
		}

		.matrix-block-steps {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-content: flex-start;
			justify-content: flex-start;
			align-items: flex-start;

			@include bp(tablet) {
				flex-direction: row;
			}

			.matrix-block-step:first-child {
				margin-left: 0;
			}

			.matrix-block-step:last-child {
				margin-right: 0;
			}

			.matrix-block-step {
				font-weight: 400;
				margin-left: 0;
				margin-bottom: 0.5rem;
				font-size: .9em;
				width: 100%;
				height: fit-content;
				border-radius: 5px;
				transition: all .24s cubic-bezier(.215, .61, .355, 1);
				background-color: #f6f6f6;

				@include bp(tablet) {
					margin-left: .625rem;
					margin-bottom: 0;
				}

				.matrix-block-step-description {
					width: 100%;
					padding: 1.25em;
					transition: background-color .24s cubic-bezier(.215, .61, .355, 1), color .24s cubic-bezier(.215, .61, .355, 1);
				}
			}

			.selected {
				background-color: var(--level-color);
				color: white;
			}
		}

		@include bp(tablet) {
			padding: 1.45em 3em 1.45em 1em;
			display: -ms-flexbox;
			display: flex;
		}
	}

	.matrix-assessment-overview-step {
		font-weight: 400;
		margin-left: 0;
		margin-bottom: 0.5rem;
		font-size: 0.9em;
		width: 100%;

		@include bp(tablet) {
			margin-left: .625rem;
			margin-bottom: 0;
		}

		.description {
			width: 100%;
			padding: 1.25em;
			background-color: #f6f6f6;
			border-radius: 3px;

			@include bp(tablet) {
				border-radius: 0;
			}
		}

		.assignment-steps {
			display: block !important;
			width: 100%;

			.graded-step {
				padding: 0.6em 0.8em;
				clear: both;
				color: white;
				border-radius: 5px;
				display: inline-block;
				margin-top: 0.5em;
				font-weight: 400;
				font-size: 0.85em;
				background-color: var(--level-color);

				.type {
					width: 22px;
					height: 22px;
					line-height: 15px;
					letter-spacing: 1px;
					text-align: center;
					font-size: 0.6em;
					text-transform: uppercase;
					margin-right: 0.6em;
				}
			}

			&.push-right {
				float: right;
				width: auto;
			}
		}
	}

	.matrix-block-clarifications {
		background-color: white;

		.content {
			padding: 1.25em 1em;
			padding-bottom: 0;
			width: 100%;

			.demand {
				padding-bottom: 0.85em;
				margin-bottom: 0.85em;
				border-bottom: $get_the_border;
				color: #3A3A3A;
				font-size: 0.9em;
				display: block;

				.title-container {
					width: 100%;
					max-width: 100%;

					.title {
						font-weight: 600;
						font-size: 1.1em;
						max-width: 31%;
						width: calc(100% - 75px);
						max-width: 100%;
						float: left;
						padding-right: 3em;
						position: relative;
						top: 50%;
						transform: translateY(-50%);
					}

					@include bp(tablet) {
						float: left;
						width: 40%;
						max-width: 40%;
					}
				}

				.steps-container {
					width: 100%;
					display: flex;
					flex-direction: row;
					align-content: flex-start;
					justify-content: flex-start;
					align-items: flex-start;

					.step {
						font-weight: 400;
						margin-left: .625rem;
						font-size: 0.9em;
						width: 100%;

						.description {
							transition: background-color 0.24s cubic-bezier(0.215, 0.61, 0.355, 1), color 0.24s cubic-bezier(0.215, 0.61, 0.355, 1);
						}

						&:last-child {
							margin-right: 0;
						}

						&:first-child {
							margin-left: 0;
						}

					}
				}

				&:last-child {
					border-bottom: 0;
				}

				.action {
					margin-right: 0.50em;

					&:last-child {
						margin-right: 0;
					}
				}

				svg {
					height: 27px;
					cursor: pointer;
				}

				@include bp(tablet) {
					display: flex;
				}
			}
		}

		.drawer {
			margin: 0em 1em;
			padding: 1em 0;
			border-top: $get_the_border;
			margin-top: 0;

			textarea {
				height: 70px;
				font-size: 0.9em;
				padding: 0.7em;
			}

			.add-new {
				.button-target {
					width: 100%;
					float: none;
					text-align: center;
					border-radius: 0;
				}
			}

			.step {
				padding: 0 !important;

				.form-row {
					padding-right: 0 !important;
				}

				&:first-child {
					margin-left: 0 !important;
				}
			}

		}
	}

	.clarification-inactive {
		display: none;
	}

	.clarification-active {
		display: block;
	}

	.matrix-block-container-active {
		display: -ms-flexbox;
	}

	.matrix-block-container-inactive {
		display: none;
	}
}

.paragraph {
	margin-top: 1em;
}

.matrix-container {
	.button-primary {
		margin-left: 0;


		&.admin {
			left: 0;
			top: 0;
		}
	}
}

.matrix-paragraph-title {
	font-size: 1em;
}

.matrix-paragraph-subtitle {
	margin-top: 0.4rem;
	margin-bottom: 0.5rem;
	font-size: 0.8em;
}