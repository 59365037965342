.expandable-container {
	width:100%;

	.expandable-header {
		background-color:white;
		border: $get_the_border;
		border-radius: $border-radius;
		cursor:pointer;
		position: relative;

		&.has-color {
			border-left-width: 4px;
		}

		.title {
			display: inline-block;
			width: calc(100% - 30px - 1.25rem);
			font-size: 1.125rem;
			font-weight: 500;
			padding: 1.25rem;
			padding-right: 0;
		}

		.trigger-container {
			float: right;
			padding: 1.25rem;
			padding-left: 0;
			height: 100%;
		}

		.trigger {
			background: #EEEEEE;
			float: right;
			width: 30px;
			height: 30px;
			margin-top: -4px;
			line-height: 27px;
			text-align: center;
			font-size: 1.4rem;
			border-radius: 100%;

			svg {
				margin: 0.4em 5px;
				width: 45%;
			}
		}

		&.open {
			box-shadow: $item-large-shadow;
		}

		.bar {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;

			.step {
				border-radius:0px !important;
				padding: 0.2em 0 !important;
				font-size: 0;
				height: 5px;
			}

			@include bp(tablet) {
				display: inline-block;
				width: 50%;
				float: right;
				position: relative;

				.step {
					font-size: 0.8em;
					height: initial;

					&:first-child {
						border-bottom-left-radius: 20px !important;
						border-top-left-radius: 20px !important;
					}

					&:last-child {
						border-bottom-right-radius: 20px !important;
						border-top-right-radius: 20px !important;
					}
				}
			}
		}

		@include bp(tablet) {
			.title {
				width: calc(100% - 30px - 2.5rem);
				padding-left: $modal_padding;
			}

			.trigger-container {
				padding-right: $modal_padding;
			}
		}
	}

	.expandable-content {
		background-color: #F3F3F3;
		border: $get_the_border;
		border-top: 0;
		border-bottom: 0;
		padding: 0 1.25rem;
		opacity: 0;
		position: relative;
		z-index:1;
		transition: padding $animation-cubic-long, opacity $animation-cubic-long;
		overflow: hidden;
		height: 0px;
		opacity: 0;
		width: 100%;

		&.open {
			padding: 1.25rem 1.25rem;
			opacity: 1;
			height: auto;
			border-bottom-left-radius: $border-radius;
			border-bottom-right-radius: $border-radius;

			@include bp(tablet) {
				padding: 1.25rem 2.5rem;
			}
		}

		&.centerContent {
			text-align:center;
		}

		&.expandable-white-bg {
			background-color: #ffffff;
		}

		&:last-child {
			&.open {
				border-bottom: $get_the_border;
			}
		}

		@include bp(tablet) {
			padding-right: 2.25rem;
			padding-left: 2.25rem;
		}
	}
}
