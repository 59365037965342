.person {
	display: inline-flex;
	align-items: center;
	justify-content: center;

	.avatar {
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;

		background: #F5F5F5;
		color: #0089C2;
		font-size: 1.25rem;
		font-weight: 300;
		height: 3rem;
		width: 3rem;
		min-height: 3rem;
		min-width: 3rem;
		border-radius: 3rem;

		img {
			max-width: 100%;
			max-height: 100%;
			width: auto;
			height: auto;
		}
	}

	.name {
		margin-left: 0.65rem;
		color: #0F0F0F;
		font-weight: 500;
		font-size: 0.85rem;
	}

	&.size--lg {
		.avatar {
			height: 4.25rem;
			width: 4.25rem;
			min-height: 4.25rem;
			min-width: 4.25rem;
			border-radius: 4.25rem;
			font-size: 1.85rem;
		}

		.name {
			font-size: 0.95rem;
		}
	}
}

.person-children {
	margin-left: 3.65rem;

	&.size--lg {
		margin-left: 4.9rem;
	}
}