.list-wrapper {
	width: 100%;

	.list-header {
		display: flex;
		margin-bottom: 0.85rem;
		align-items: center;
	}

	.list-actions {
		flex:1;
		text-align: right;
	}

	.list-title {
		flex:1;
	}

	.list-content {
		.table {
			display: table;
			width: 100%;
			background-color: white;
			border-radius: 8px;
			border: 1px solid #f0f0f0;
			box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);

			a {
				text-decoration: none;
				color: #363636;
			}

			.row {
				display: table-row;
				width: auto;
				clear: both;

				.cell {
					display: table-cell;
					padding: 1.25rem 1.25rem;
					border-bottom: 1px solid #f0f0f0;
					vertical-align: middle;

					&.grow {
						width:45%;
					}

					&.shrink {
						white-space: nowrap;
						width: 1px;

						.dot {
							width: auto;

							@media (min-width: 575px) {
								width: 80px;
							}
						}

						.dot-content {
							display: none;

							@media (min-width: 575px) {
								display: block;
							}
						}
					}
				}

				&:hover {
					background-color: #f0f0f0;
				}

				&.table-head {
					font-size: 0.85rem;

					&:hover {
						background-color: white;
					}

					.cell {
						padding: 0.85rem 1.25rem;
						vertical-align: middle;
						white-space: pre-wrap;

						@media (min-width: 450px) {
							white-space: pre;
						}
					}
				}
			}

			.dots-container {
				.dot {
					border:0px;
				}
			}
		}

		.grid {
			.card {

			}
		}
	}
}
