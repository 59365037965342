$breakpoint: 830px;

.page.groups {
	.c--card {
		position: relative;

		.meta-container {
			text-align: left;
		}

		.group-header {
			align-items: normal;
			display: flex;
			justify-content: space-between;
			margin-left: 2.25rem;
			flex-direction: row;

			.card-meta {
				margin-top: 0;
				align-self: flex-start;
			}
		}

		/* Media query for mobile devices */
		@media (max-width: $breakpoint) {
			.group-header {
				flex-direction: column;
			}
		}

		.expandable-container {
			.expandable-header {
				border: 0;
				background-color: transparent;
				position: static;

				.title {
					display: none;
				}

				.trigger-container {
					padding: 0;
					height: 30px;
					width: 30px;
					position: absolute;
					top: 1.75rem;
					left: 1.75rem;
					z-index: 9;
				}
			}

			.expandable-content {
				padding-left: 0 !important;
				padding-right: 0 !important;
				border: 0;
				border-bottom: 0 !important;
				border-radius: 0;
				background-color: transparent;

				.layout.flex {
					flex-direction: column;
				}

				@media screen and (min-width: $breakpoint) {
					.layout.flex {
						flex-direction: row;
					}
				}
			}
		}

		.layout.flex {
			align-items: flex-start;
		}

		.description {
			max-width: 50%;
			margin-bottom: .75rem;

			@media screen and (min-width: $breakpoint) {
				margin-bottom: 0;
			}
		}
	}

	.all-fold-button {
		cursor: pointer;
		margin-bottom: .55rem;

		span.icon {
			margin-right: .25rem;
			position: relative;
			top: 2px;

			svg {
				transition: 0.24s cubic-bezier(0.215, 0.61, 0.355, 1);
			}
		}

		&.open {
			svg {
				transform: rotate(90deg);
			}
		}

		&.closed {
			svg {
				transform: rotate(-90deg);
			}
		}
	}

}