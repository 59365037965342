@import "~react-big-calendar/lib/css/react-big-calendar.css";

.c--schedule {
	display: block;
	overflow: hidden;

	.rbc-time-view {
		border: 0;
	}

	.rbc-time-header {
		.rbc-time-header-content {
			border-bottom: 1px solid #DDD;

			.rbc-header {
				border-bottom: 0;
				padding-top: .65rem;
				padding-bottom: .65rem;

				&.rbc-today {
					//background-color: rgba(0, 0, 0, 0.03);
					color: #0079DB;
					//text-decoration: underline;
					background-color: transparent;

					a {
						position: relative;

						&:after {
							content: "";
							position: absolute;
							top: 100%;
							left: 0;
							height: .2rem;
							margin-top: .2rem;
							right: 0;
							background-color: #0079DB;
						}
					}

				}
			}

			&:last-child {
				border-right: 1px solid #DDD;
			}
		}

		.rbc-allday-cell {
			display: none !important;
		}

		.rbc-time-header-gutter {
			padding-right: 7px;
		}
	}

	.rbc-time-content {
		border-top: 0;
		overflow: hidden;

		.rbc-timeslot-group {
			min-height: 90px;
			border-bottom: 0;
		}

		.rbc-events-container {
			margin-right: 0;
		}

		//calender selectionbox
		.rbc-slot-selection {
			background-color: transparent;
		}

		.rbc-event {
			background-color: white;
			color: black;
			border: 1px solid #c8c9c8;
			border-radius: 5px;
			box-shadow: 0 3px 15px rgba(0, 0, 0, 0.03);
			transition: box-shadow 0.24s cubic-bezier(0.215, 0.61, 0.355, 1);
			padding: 5px;

			&.rbc-selected {
				background-color: rgb(231, 231, 231);
			}

			&.lesson {
				border-left-width: 5px;
				border-left-color: #0079db;

				&.hasExamination {
					border-left-color: #e8348e;
				}
			}

			&.attendance {
				border-left-width: 5px;
				border-left-color: #0079db;

				&.hasExamination {
					border-left-color: #e8348e;
				}

				&.validAttendance {
					background-color: #e8fbf0;

					&.rbc-selected {
						background-color: #E7E7E7;
					}

				}
			}

			&.calendarEvent {
				background: #e6f4fc repeating-linear-gradient(45deg,
						transparent 0px, transparent 7.5px, #fff 7.5px, #fff 8.5px,
						transparent 8.5px, transparent 16px, #fff 16px, #fff 17px);
				background-size: 12px 12px;
			}

			&.assignment.hasExamination {
				border-left-color: #e8348e;
				border-left-width: 5px;
			}

			&.meeting {
				border: 1px #B7B7B7;
				background-color: #ffffff;

				&.no-booked {
					background-image: url('/src/assets/img/bg_stripes-transp.png');
				}
			}

			.rbc-event-content {
				font-size: var(--size-14);
			}

			.rbc-event-label {
				font-size: 70%;
				line-height: 1;
			}

			.teams-meeting-indicator {
				position: absolute;
				top: 0px;
				right: 0px;
				padding: 5px;
				padding-left: 2px;
			}

			.event-info {
				margin-top: 4px;
			}

			.event-meta {
				margin-top: 5px;

				.status {
					display: flex;
					align-items: center;

					.indicator {
						width: 0.85rem;
						height: 0.85rem;
						border-radius: 3px;
						margin-right: 4px;
					}
				}
			}
		}

		.rbc-day-slot {
			background-color: transparent;

			.rbc-time-slot {
				border: 0;
			}

			&.rbc-today {
				//background-color: rgba(0, 0, 0, 0.03);
				background-color: transparent;
			}



			&:last-child {
				border-right: 1px solid #DDD;
			}
		}
	}

	.rbc-row-bg {
		a {
			color: #000;
		}

		.rbc-day-bg {
			background-color: transparent;

		}
	}

	.rbc-date-cell {
		position: relative;
		min-width: 50px;
		min-height: 50px;

		.rbc-day-bg {
			background-color: transparent;
		}

		&.rbc-off-range-bg {
			background-color: transparent;

		}

		&.rbc-now {
			font-weight: normal;
		}

		.rbc-row-segment {
			position: relative;

		}

	}

	.rbc-month-view {
		border-top: none;


		.rbc-header {
			border-top: none;
			padding: 1rem;
			text-align: left;
			font-size: 1.1rem;
			font-weight: normal;
		}
	}


	.rbc-month-row {

		.rbc-event {
			outline: none;
			cursor: auto;


			&.rbc-selected {
				border: none;
				outline: none;
				background-color: transparent;
			}

			.month-event {

				.month-text {
					display: flex;
					flex-direction: column;
					height: max-content;
					width: 90%;
					font-weight: normal;


					.month-container {
						display: flex;
						flex-direction: row;
						flex-wrap: wrap;
						align-items: center;

						* {
							overflow-wrap: break-word;
						}

					}
				}
			}
		}
	}



	.rbc-day-slot:first-of-type {
		background-color: black;
	}

	.rbc-month-row {
		display: block;
		flex: auto;
		position: relative;
		min-height: 15vh;
		background-color: #FFFFFF;

		.rbc-row:nth-child(even) {
			position: relative;
		}

		.rbc-row:nth-child(odd) {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
		}


		.rbc-row-content {
			border-top: 0;


			.rbc-event-content {
				margin: 0;
				top: 0;
				left: 0;
				bottom: 0;
				color: #000;
				display: flex;
				flex-direction: column;
			}

		}

	}

	.rbc-agenda-view {
		overflow: unset !important;

		.rbc-agenda-table {
			border: 0;
			border-collapse: separate;
			border-spacing: 0 .55rem;

			thead {
				display: none;
			}

			tbody {
				tr {
					border-bottom: 5px solid transparent;
					border: 0;

					td {
						border-left: 0;
						padding: 1rem;
					}

					td.rbc-agenda-time-cell {
						padding-left: 0;
					}

					&.lesson {
						td.rbc-agenda-event-cell {
							border-left-color: #0079db;
							border-left-width: 5px;
						}

						&.hasExamination {
							td.rbc-agenda-event-cell {
								border-left-color: #e8348e;
							}
						}
					}

					&.attendance {
						border-left-color: #0079db;

						td.rbc-agenda-event-cell {
							border-left-width: 5px;
						}

						&.hasExamination {
							td.rbc-agenda-event-cell {
								border-left-color: #e8348e;
							}
						}
					}

					&.assignment {
						&.hasExamination {
							td.rbc-agenda-event-cell {
								border-left-width: 5px;
								border-left-color: #e8348e;
							}
						}
					}

					td.rbc-agenda-event-cell {
						cursor: pointer;
						border-radius: 5px;
						background-color: white;
						box-shadow: 0 3px 15px rgba(0, 0, 0, 0.03);
						transition: all 0.3s cubic-bezier(0.79, 0.14, 0.15, 0.86);
						border: 1px solid #c8c9c8;

						.event-info {
							display: flex;
							align-items: center;
							justify-content: space-between;

							.status {
								display: flex;
								align-items: center;
								justify-content: flex-end;

								.indicator {
									width: 0.85rem;
									height: 0.85rem;
									border-radius: 3px;
									margin-right: 4px;
								}
							}

							.agenda-event-title {
								span.icon {
									position: relative;
									top: 2px;
									margin-left: .35rem;
								}
							}

							>div {
								width: calc(100% / 3);

								&:last-child {
									text-align: right;
								}
							}
						}

						&:hover {
							box-shadow: 0 10px 20px rgba(100, 100, 100, 0.09), 0 6px 6px rgba(100, 100, 100, 0.13);
						}
					}

					&.attendance {
						.rbc-agenda-event-cell {
							border-left-width: 3px;
							border-left-color: inherit;
						}
					}
				}
			}
		}

		.rbc-agenda-date-cell {
			display: none;
		}
	}

	.rbc-time-gutter {
		.rbc-timeslot-group {
			padding-right: 2px;
		}
	}

	.selection-display {
		.spinner-container {
			width: auto;
			margin: 0;
			margin-left: 1rem;

			.loading-spinner {
				margin: 0;
				width: 2.55rem;
				height: 2.55rem;
			}
		}
	}

	&.variant-striped {
		.rbc-time-content {
			.rbc-time-gutter {
				background-color: #f5f5f5;
			}
		}
	}

}

.c--button.no_background {
	background-color: transparent;
	border: none;
	color: #000;
	box-shadow: none;

	&:hover {
		background-color: transparent;
	}

}

button>* {
	pointer-events: none;
}




.c--button.dropDown {
	background-color: #fff;
	color: #000;
	border: 1px solid #c9c9c9;
	margin-left: 1rem;



	&:hover {
		background-color: #f5f5f5;
	}

	span {
		padding-left: 2rem;
		margin-left: auto;

		.icon {
			width: .2rem;
		}
	}

}

.calendar-container {
	position: relative;
}


.calenderToolBar {
	display: flex;
	flex-direction: row;
	margin-bottom: 2rem;
	flex-wrap: wrap;
	padding-bottom: 1rem;
	align-items: flex-end;

	.filtering {
		margin-left: auto;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: flex-end;


		button.c--button.dropdown {
			background-color: #fff;
			color: #000;
			border: 1px solid #c9c9c9;

			&:hover {
				background-color: #f5f5f5;
			}

			.icon {

				width: 60px;
				height: 60px;
				justify-content: flex-end;

			}

			.icon-standard {

				stroke: #000;
				color: #000;
			}

			svg {

				width: 1.5rem;
				height: 1.5rem;


			}
		}
	}

}




.in_scheduleFrom {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
	width: 93%;
	margin: 0 auto;


	.timeStart_scheduleFrom {
		width: 30%;

		.react-datepicker-wrapper {
			width: 100%;
		}

		.time-picker-container {
			width: 100%;
		}

		input {
			padding: 1.4rem;
		}

	}

	.time_scheduleFrom {
		width: fit-content;
	}

	.submitTime {
		width: 100%;
		margin: 2rem 0;

		input {
			width: auto;
		}
	}
}

.monthIcon {
	background-color: #ffffff;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	margin-right: .2rem;
	border: 1px solid #b7b7b7;

	&.lesson {
		background-color: #0079db;
		border: none;
	}

	&.examination {
		background-color: #e8348e;
		border: none;
	}

	&.calendarEvent {
		background-color: #bbdfef;
	}
}

.rbc-header {
	text-transform: capitalize;
}

.todaysMonth {
	color: #0079DB;
	position: relative;

	&:after {
		content: "";
		position: absolute;
		top: 100%;
		left: 0;
		height: .2rem;
		margin-top: .2rem;
		right: 0;
		background-color: #0079DB;
	}
}

.dropDownContainer {
	z-index: 2;
	background-color: #fff;
	padding: .8rem 1rem .2rem 1rem;
	width: max-content;
	display: flex;
	flex-direction: column;
	border-radius: 5px;

	.filterModal {
		display: flex;
		flex-direction: column;
		width: max-content;
		margin-left: 2rem;
		margin-top: .2rem;

		.checkbox {
			margin: .8rem 0 0 0;
		}

		.title {
			padding: 0;
		}
	}
}

.rbc-event,
.rbc-day-slot .rbc-background-event {
	background-color: transparent;
}