.assessment-block {
	.add-assessment-button {
		margin-top: 1.25rem;
	}

	.select-matrices-form,
	.display-matrix {
		.row {
			margin-bottom: 1.25rem;
			font-size: var(--size-14);
			flex-direction: column;

			.checkbox {
				margin-left: 1rem;
			}

			>div {
				margin-right: 1.25rem;

				&:last-child {
					margin-right: 0;
				}
			}

			@media (min-width: 1024px) {
				flex-direction: row;
			}
		}

		.column-text {
			width: 100%;
			margin-bottom: 0.75rem;

			@media (min-width: 1024px) {
				margin-bottom: 0;
				width: 35%;
			}
		}

		.assessmentGoal-dotContainer {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			z-index: 2;

			span:first-of-type {
				margin-right: 1rem;
			}

			.dots-container.show-content {
				width: auto;
				margin-left: 0.5rem;

				.text {
					display: none;
				}

				.dot-content {
					&::after {
						z-index: 3;
					}
				}
			}

			.dots-container.collapse {
				width: auto;
			}
		}

		.cells {
			flex: 1;
			flex-direction: column;

			.cell {
				padding: .75rem;
				margin-bottom: .55rem;
				background-color: #f4f5f4;
				border-radius: 6px;
				flex: 1;

				@media (min-width: 1230px) {
					margin-bottom: 0;
					margin-right: .55rem;
				}

				&:last-child {
					margin-right: 0;
				}
			}

			@media (min-width: 1230px) {
				flex-direction: row;
			}
		}
	}

	.display-matrix {
		.inner-content {
			background-color: white;
		}
	}

	.goal-matrix {
		margin-bottom: 1rem;
	}

	.row-tools {
		margin-right: 0.65rem;
	}

	.display-matrix {
		.cell {
			position: relative;

			span.icon[name="Check"] {
				display: none;
				align-items: center;
				justify-content: center;
				padding: 5px;
				width: 1.75rem;
				height: 1.75rem;
				border-radius: 50%;
				background-color: var(--level-color);
				border: 3px solid white;
				position: absolute;
				bottom: calc(-0.875rem - 3px);
				left: calc(50% - 0.875rem);
				z-index: 1;

				path {
					stroke: white !important;
					stroke-width: 3px !important;
				}
			}

			&::after {
				display: none;
				position: absolute;
				content: '';
				height: 0px;
				bottom: -6px;
				width: 100%;
				left: 0px;
				border-top: 3px solid var(--level-color);
				border-radius: 8px;
			}

			&.selected {
				background-color: var(--level-color);
				color: white;

				&::after {
					display: block;
				}

				span.icon[name="Check"] {
					display: flex;
				}
			}
		}

		.cell-container {
			flex: 1;
			margin-bottom: 0.55rem;

			>.cell {
				margin: 0;
			}

			.assessment-detail {
				display: block;
				text-decoration: none;
				margin-top: 1.1rem;

				&:first-of-type {
					margin-top: 0.4rem;
				}
			}

			@media (min-width: 1230px) {
				margin-bottom: 0;
				margin-right: .55rem;
			}

			&:last-child {
				margin-right: 0;
			}
		}

		.feedbacks {
			padding-top: 1rem;

			.collapsible-trigger {
				font-weight: 500;
			}

			.icon[name="Chevron"] {
				position: relative;
				top: 2px;
			}
		}

		.c--expandable {
			margin-top: 1.25rem;

			&:first-child {
				margin-top: 0;
			}
		}

		.paragraph {
			margin-bottom: 2.25rem;
			padding-bottom: 1rem;

			&:last-child {
				margin-bottom: 0;
				padding-bottom: 0;
			}
		}

		.permissions {
			display: flex;
			align-items: center;
			margin-right: 1rem;
			color: #707070;

			.permission {
				display: flex;
				align-items: center;
				margin-left: .85rem;
				font-size: 1rem;
				font-weight: 400;

				span.icon {
					margin-right: .25rem;
					fill: #707070;

					svg {
						position: relative;
						top: 2px;

						path {
							fill: #707070 !important;
						}
					}
				}
			}
		}
	}

	.clarifications {
		margin-bottom: 1.25rem;

		.collapsible {
			svg {
				position: relative;
				top: 2px;
			}
		}

		.collapsible-content {
			.icon-standard {
				stroke: var(--color--meta);
			}
		}

		&.select-matrices-form {
			.collapsible {
				padding-left: 6rem;

				.column-text {
					width: 39%;
				}
			}
		}

		&.display-resource {
			.collapsible {
				padding-left: 2.5rem;

				.column-text {
					width: 36%;
				}
			}
		}
	}

}