.teams-header-spacing {
	height: 4.55rem;
	width: 100%;
	display: block;
}

.teams-header {
	display: flex;
	align-items: center;
	top: 0;
	left: 0;
	height: 4.55rem;
	width: 100%;
	margin-bottom: 0.8rem;
	z-index: 999;

	.cl-container {
		width: 1rem;
		height: 1rem;
		left: none;
		margin: 0;
	}

	svg {
		width: 1rem;
		height: 1rem;
		left: -4px;
		top: 1px;
		position: relative;
	}

	.content {
		overflow: hidden;
		display: flex;
		align-content: center;
		align-items: center;
		flex: 1;

		&.right {
			flex: initial;
			text-align: right;
		}

		.header-action {
			display: inline-flex;
			margin: 0 0.45rem;
			border-radius: 100%;
			width: 2.55rem;
			height: 2.55rem;
			align-self: center;
			align-items: center;
			justify-content: center;
			position: relative;
			//top: 2px;
			cursor: pointer;

			&:hover {
				background-color: #e7e7e6;
			}

			svg {
				position: relative;
				top: 2px;
				left: 0;
				width: 1.1rem;
				height: 1.1rem;
			}
		}

		.c--button {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			padding: 0.95rem 1rem;
			font-weight: 400;
			text-transform: none;
			border: 0 !important;
			font-size: 0.72rem;

			&.back {
				background-color: transparent !important;
				margin-right: 1rem;
				cursor: pointer;
				border-radius: 6px;

				&:hover {
					background-color: #e7e7e6 !important;
				}
			}

			svg {
				.icon-standard {
					stroke: white;
				}

				.icon-standard-fill {
					fill: white;
				}
			}
		}

		.c--button,
		.c--button.save,
		.c--button.secondary {
			margin-left: 1.25rem;

			&:first-child {
				margin-left: 0;
			}
		}

		.actions {
			align-items: center;
			justify-content: center;
			display: inline-flex;

			.search-box-container {
				.search-box-icon {
					width: 16px;
					height: 16px;
				}

				input {
					height: 41px;
					font-size: 0.8rem;
				}
			}
		}

		.page-title {
			font-size: 1.25rem;
			font-weight: 500;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}
}