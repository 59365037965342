.meeting--slots {
	.collapsible {
		.collapsible-trigger {
			border-top: 1px solid #dbdbdb;
			padding: 1.24rem 1.88rem;
			font-weight: 500;

			svg {
				display: none;
			}
		}

		.collapsible-content {
			padding: 0 1.88rem;
			border-bottom: 0;
		}
	}

	.open-indicator {
		width: 25px;
		height: 25px;
		padding: 4px;
		margin-left: 0.45rem;
		border-radius: 12.5px;
		background-color: #eee;
		font-size: var(--size-20);
		display: flex;
		align-items: center;
		justify-content: center;

		svg {
			display: block !important;
			left: 0 !important;
			top: 0 !important;
			width: 14px !important;
			height: 14px !important;
		}
	}

	.slots-container {
		display: grid;
		grid-template-columns: auto;
		grid-column-gap: 1.24rem;
		grid-row-gap: 1.24rem;
		align-items: center;
		padding-bottom: 1.9rem;

		@media (min-width: 600px) {
			grid-template-columns: auto auto auto;
		}
	}

	.slot {
		.radiobutton {
			display: flex;
			align-items: center;
			border: 1px solid #c9c9c9;
			border-radius: 5px;
			padding: 0 0.88rem;
			cursor: pointer;
			height: 60px;

			&.active {
				border-color: #0075d5;
			}

			&.disabled {
				background-color: #f5f5f5;
				border-color: #f5f5f5;
				text-decoration: line-through;
				cursor: not-allowed;

				.check {
					display: none;
				}
			}
		}
	}

	&:last-child {
		.collapsible {
			.collapsible-content {
				border-bottom: 1px solid #dbdbdb;
			}
		}
	}

}
