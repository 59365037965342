.time-picker-container {
	background-color: #F9F9F9;
	border-radius: 3px;
	border: 1px solid #C9C9C9;
	font-weight:400;
	font-size:1rem;
	display: flex;

	align-items: center;
	justify-content: center;

	input.time-part {
		background-color: transparent !important;
		border: 0 !important;
		padding: 15px;
		min-height: 58px !important;
		text-align: center;
		min-width: 0px !important;

		appearance: none;
		-moz-apperance: none;
		-webkit-appearance: none;

		flex-grow: 1;
	}

	span {
		flex-shrink: 1;

		&.time-switcher {
			padding: 0 0.7rem;
			font-weight: 500;
			cursor: pointer;
		}
	}
}
