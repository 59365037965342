/**
* Colors
*/
$color-primary: #73D266;
$color-primary-hover: #7EE172;
$color-primary-active: #7DF26F;

$color-secondary: #FAFAF7;
$color-secondary-hover: #F2F2EF;
$color-secondary-active: #EBEBE9;

$color-action: #f5f5f5;
$color-action-hover: #F2F2EF;
$color-action-active: #EBEBE9;

$color-outline: #DFE1E2;
$color-meta-text: rgb(159, 159, 159);
$color-disabled: #C4C5C6;

$color-grade-f: #AAC4CE;
$color-grade-e: #59B0F0;
$color-grade-c: #577BF2;
$color-grade-a: #6416E2;

$border-color: #E4E4EB;
//$border-color: rgb(238, 238, 242);
$border-width: 1px;
$get_the_border: $border-width solid $border-color;

$input-border-color: #C9C9C9;
$input-border-width: 1px;
$get_the_input_border: $input-border-width solid $input-border-color;

$item-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
$item-large-shadow: 0px 3px 15px rgba(0, 0, 0, 0.05);
/**
 * Sizes
 */
$border-radius: 5px;
$meta-text: 0.875em;
$title-text: 1.25em;
$regular-text: 1em;

/**
* Cards
*/
$card-hover-shadow: 0 19px 38px rgba(100,100,100,0.20), 0 15px 12px rgba(100,100,100,0.12);

$modal_padding: 2.5rem;

/**
* Animations
*/
$animation-cubic-long: 0.5s cubic-bezier(0.23, 1, 0.32, 1);
$animation-cubic-short: 0.24s cubic-bezier(0.215, 0.61, 0.355, 1);

/**
* Buttons
*/
$button-primary-border-color: $border-color;
$button-primary-border-width: 2px;
$button-border-radius: 4px;

/**
 * Block
 */
$block-padding-size: 1.45rem;
