.schedule.section {
    .navigation-buttons-container {
        margin-top: 1rem;
        margin-left: 1.25rem;
        display: flex;
        align-self: flex-end;

        @include bp(tablet) {
            margin-top: 0;
            float: right;
        }
    }

    .c--button {
        height: 48px;
    }

    .select {
        display: inline-flex;
        vertical-align: middle;

        select {
            font-size: 0.875rem;
            padding: .91rem 1.35rem;
            padding-right: calc(1.35rem + 10px);
            height: 48px;
        }
    }

    .cbp-tm-menu {
        margin-left: 1rem;

        >li>span {
            position: relative;
            top: -3px;
        }

        span.icon[name="Check"] {
            svg {
                position: relative;
                top: -2px;
                height: .9rem;
                width: .9rem;
            }
        }
    }

    &.mobile {
        .navigation-buttons-container {
            margin-top: 0;
            float: none;
        }
    }
}