$height: 4.15rem;

.desktop-header-spacing {
	height: $height;
	width: 100%;
	display: block;
}

.desktop-header {
	position: fixed;
	background-color: #f8f8f8;
	border-bottom: 1px solid #e5e5e5;
	display: flex;
	align-items: center;
	top: 0;
	left: 0;
	height: $height;
	width: 100%;
	padding: 0 1.3rem;
	padding-top: env(safe-area-inset-top);
	z-index: 999;

	.content {
		display: flex;
		flex: 1;
		align-content: center;
		align-items: center;

		&.right {
			flex: initial;
			text-align: right;
		}

		.header-action {
			display: inline-flex;
			margin: 0 0.45rem;
			border-radius: 100%;
			width: 2.55rem;
			height: 2.55rem;
			align-self: center;
			align-items: center;
			justify-content: center;
			position: relative;
			//top: 2px;
			cursor: pointer;

			&:hover {
				background-color: #e7e7e6;
			}

			svg {
				position: relative;
				top: 2px;
				left: 0;
				width: 1.1rem;
				height: 1.1rem;
			}
		}

		.c--button {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			padding: 0.95rem 1rem;
			background-color: #005da9;
			font-weight: 400;
			text-transform: none;
			color: white;
			border: 0 !important;
			font-size: 0.72rem;
			letter-spacing: 0.7px !important;

			&:hover {
				background-color: #0075d5 !important;
			}

			&.back {
				background-color: transparent !important;
				margin-right: 1rem;

				&:hover {
					background-color: #e7e7e6 !important;
				}
			}

			svg {
				.icon-standard {
					stroke: white;
				}

				.icon-standard-fill {
					fill: white;
				}
			}
		}

		.c--button,
		.c--button.save,
		.c--button.secondary {
			margin-left: 1.25rem;
		}

		.actions {
			align-items: center;
			justify-content: center;
			display: inline-flex;

			.search-box-container {
				.search-box-icon {
					width: 16px;
					height: 16px;
				}

				input {
					height: 37px;
					font-size: 0.8rem;
				}
			}
		}

		img.logotype {
			height: 2.4rem;
			border-radius: 4px;
		}
	}
}