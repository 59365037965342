$animation-long: 0.5s cubic-bezier(0.23, 1, 0.32, 1);
$animation-short: 0.24s cubic-bezier(0.215, 0.61, 0.355, 1);

.graph {
	&.progress {
		transition: $animation-long;
		position:relative;
		z-index:3;
		border:1px solid #9E82EB;
		border-radius:15px;
		height:23px;
		width:100%;

		.progress-bar {
			position:absolute;
			top:2px;
			left:3px;
			background-color: #9E82EB;
			border-radius:15px;
			height:17px;
			max-width: calc( 100% - 5px );
			transition: $animation-long;
		}
	}

	&.line {
		padding: 0;
		width: 100%;

		.bar-container {
			width:100%;
			display: flex;

			&:first-child {
				.step {
					&:first-child {
						border-top-left-radius:20px;
					}

					&:last-child {
						border-top-right-radius:20px;
					}
				}
			}

			&:last-child {
				.step {
					&:first-child {
						border-bottom-left-radius:20px;
					}

					&:last-child {
						border-bottom-right-radius:20px;
					}
				}
			}
		}

		.step {
			display:inline-block;
			color:white;
			padding:0.5em 0;
			padding-right: 23px;
			padding-left: 7px;
			transition: $animation-short;
			text-align:center;
			font-weight:400;
			font-size:0.8em;
		}
	}

	&.pie {
		$chart-size: 250px;

		position: relative;
		width: $chart-size;
		height: $chart-size;
		line-height: 100px;
		border-radius: 50%;
		background: aqua;

		.punch-out {
			$punch-size: 58%;

			position: absolute;
			left:50%;
			top:50%;
			transform: translate( -50%, -50% );
			background-color: #f5f5f5;
			width: $punch-size;
			height: $punch-size;
			border-radius: 50%;
			z-index:3;
		}

		.pie__chart {
			position: absolute;
			top: 0;
			left: 50%;
			width: 50%;
			height: 100%;
			border-radius: 0 100% 100% 0 / 50%;
			background-color: inherit;
			transform-origin: left;

			&.chart1 {
				background: blue;
				transform: rotate(0deg);
			}

			&.chart2 {
				background: green;
				transform: rotate(90deg);
			}

			&.chart3 {
				background: yellow;
				transform: rotate(180deg);
				z-index:2;
			}

			&:last-child {
				background: red;
				transform: rotate(270deg);
			}
		}
	}
}
