$border-radius: 5px;
$color-background: #F5F5F5;
$color-background-hover: #DBDBDB;
$color-text: #0F0F0F;
$spacing-margin: 1.35rem;
$spacing-padding: 1.25rem;

.verdict {
	.verdict-cells {
		display: flex;
		padding: 1.35rem 0;
		padding-top: 0;

		.cell {
			flex: 1;
			border-radius: $border-radius;
			background-color: $color-background;
			color: $color-text;
			padding: $spacing-padding 1rem;
			margin-left: $spacing-margin;

			&:first-child {
				margin-left: 0;
			}
		}
	}

	&.verdict-display-container {
		padding: 1.35rem 0;
	}

	&.verdict-level-form {
		.verdict-cells {
			.cell {
				cursor: pointer;

				&:hover {
					background-color: $color-background-hover;
				}
			}
		}
	}

	&.verdict-feedback-display {
		display: flex;

		.text {
			flex: 1;
			background-color: $color-background;
			color: $color-text;
			padding: $spacing-padding 1rem;
			border-radius: $border-radius;
		}
	}

	&.verdict-assessment-display {
		.main-graph {
			cursor: pointer;
		}

		.assessment-list {
			padding-top: 1.25rem;

			.assessment {
				display: flex;
				padding: 0.95rem 0;
				align-items: start;
				flex-flow: column;

				.title {
					flex: 1;
				}

				.graph-container {
					flex: 1;
					width: 100%;
				}
			}

			@media (min-width: 845px) {
				.assessment {
					align-items: end;
					flex-flow: row;

					.title {
						flex: 0;
						flex-basis: 30%;
					}
				}
			}
		}
	}

	&.verdict-feedback-form {
		display: flex;

		.input {
			flex: 1;
			background-color: $color-background;
			color: $color-text;
			padding: $spacing-padding 1rem;
			border-radius: $border-radius;

			textarea {
				background: $color-background;
				border-color: transparent;
			}
		}
	}

	&.verdict-entry {
		.collapsible {
			margin-bottom: 1rem;

			.collapsible-trigger {
				font-size: 1.15rem;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	&.verdict-widget {
		display: inline-flex;
		align-items: center;
		justify-content: center;

		.verdict-levels {
			display: flex;
			align-items: center;
			justify-content: center;

			.level-dot {
				width: 11px;
				height: 11px;
				background-color: $color-background;
				border-radius: 6px;
				margin-right: 0.4rem;

				&:last-child {
					margin-right: 0;
				}
			}
		}

		.level-text {
			margin-left: 0.65rem;
			font-size: 0.9rem;
		}
	}

}
