.card-task {
	position: relative;

	&:after {
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 3rem;
		background: linear-gradient(top, rgba(255, 255, 255, 0.3), transparent);
		background: -webkit-linear-gradient(top, transparent, transparent);
	}

	.card_title {
		position: relative;
		overflow: hidden;
		width: 100%;
		height: 50px;
		line-height: 25px;

		&:before {
			content: "";
			float: left;
			height: 50px;
			width: 5px;
			margin-right: -5px;
		}

		*:first-child {
			float: right;
			width: 100%;
			margin-left: -5px;
		}

		&:after {
			content: "\02026";

			box-sizing: content-box;
			-webkit-box-sizing: content-box;
			-moz-box-sizing: content-box;

			float: right;
			position: relative;
			bottom: 100%;
			right: 0;
			width: 3em;
			margin-left: -3em;
			padding-right: 5px;
			text-align: right;
			font-size: 0.7em;
			margin-top: 2px;

			background-size: 100% 100%;
			background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(white), color-stop(50%, white));
			background: -moz-linear-gradient(to right, rgba(255, 255, 255, 0), white 50%, white);
			background: -o-linear-gradient(to right, rgba(255, 255, 255, 0), white 50%, white);
			background: -ms-linear-gradient(to right, rgba(255, 255, 255, 0), white 50%, white);
			background: linear-gradient(to right, rgba(255, 255, 255, 0), white 50%, white);
		}

	}

	.task-meta-container {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		white-space: nowrap;
		margin-bottom: .5em;

		.card-meta {
			margin-left: .5em;
			margin-right: .5em;
			overflow: hidden;
			text-overflow: ellipsis;

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}

}