.component-title {
	font-weight: 500;
	color: #373737;

	&.section_title {
		font-size: 1.625rem;
		margin-bottom: 1.75rem;

		svg {
			vertical-align: middle;
			height: 2rem;
			width: 2rem;
			display: inline;
			margin-right: .4rem;

			position: relative;
			//top: -2px;
		}

		> a {
			vertical-align: middle;

			&:first-child {
				margin-left: 0;
			}
		}

		.see-more {
			margin-left: 1rem;

			@include bp(tablet) {
				margin-left: 2rem;
			}
		}

		> span {
			display: inline;
			vertical-align: middle;
		}

		span.icon {
			display: inline-block;
			vertical-align: text-bottom;
		}

		.c--button {
			vertical-align: middle;
			margin-left: 0.85rem;

			@include bp(tablet) {
				margin-left: 1.45em;
			}

			span.icon {
				position: relative;
				top: -2px;
			}

			svg {
				height: 1rem;
				width: 1rem;
			}
		}
	}

	&.content_title {
		font-size: 1.15rem;
		margin-top: 2.5rem;
	}
}