.menu-page {
	margin-top: env(safe-area-inset-top);
	padding-top: 1.55rem;
	padding-bottom: 5rem;
	background-color: white;
	position: fixed;
	top: 3.5rem;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 100;
	overflow-y: auto;

	.items-container {
		display: flex;
		flex-direction: column;

		.menu-item {
			display: flex;
			flex-direction: row;
			align-items: center;
			padding: 0.45rem 1.25rem;

			.menu-item-icon {
				flex: 0;
				margin-right: 0.55rem;
				margin-bottom: 0;
				position: relative;

				svg {
					position: relative;
					top: 2px;
				}

				.badge {
					top: 5px;
				}

				.badge > span {
					font-size: .65rem;
				}

				.flag {
					height: 16px;
					width: 24px;
					object-fit: cover; 
					border-radius: 2px;
				}

				.person {
					height: 24px;
					width: 24px;
				}
			}

			span {
				flex: 1;
				font-size: 16px;
			}

			&:hover {
				background-color: #EAEAE9;
			}
		}
	}

	.menu-divider {
		.divider-label {
			margin-top: 2rem;
			font-weight: 600px;
			font-size: 14px;
			padding: 0 1.25rem;

			&:after {
				content: '';
				display: block;
				width: 100%;
				height: 1px;
				background-color: #dbdbdb;
				opacity: 0.5;
				margin-top: 0.45rem;
			}
		}

		&:first-child {
			.divider-label {
				margin-top: 0;
			}
		}
	}

}
