.time-picker {
	position:relative;

	.input-container {
		display:none;

		@include bp(tablet) {
			display:block;
		}
	}

	.fallback {
		display:block;

		@include bp(tablet) {
			display:none;
		}

		input {
			padding: 15px;
			border-radius: $border-radius;
			width: 100%;
			font-size: 1em;
		}

		input[type=time] {
			width:100% !important;
		}
	}

	input.output {
		background-color: #f5f5f5 !important;
		border: $get_the_input_border !important;
	}

	.time-selector {
		position:absolute;
		right:0;
		top:60px;
		display:none;
		padding-top:1.25em;
		background-color:white;
		width:350px;
		border:$get_the_border;
		border-radius: $border-radius;
		text-align:center;
		z-index:9999;

		&.open {
			display:block;
		}

		.colon-separator {
			color: black;
			font-size: 2.25em;
			text-align:center;
			line-height:43px;
			height:43px;
			position:relative;
			top:-3px;
		}

		input {
			background-color: white !important;
			border: 0 !important;
			border-radius:0 !important;
			padding:0 !important;
			color: #9B9B9B !important;
			font-size: 2.25em !important;
			display: inline-block !important;
			width: 55px !important;
			text-align: center !important;
		}

		.save-btn {
			width:100%;
			margin-top:1.25em;
			padding:0.75em 0;
			background-color:#f5f5f5;
			text-transform: uppercase;
			font-size:0.85em;
			font-weight:500;
			letter-spacing:1px;
			color: #3A3A3A;
			border-top:$get_the_border;
			cursor:pointer;
		}
	}
}