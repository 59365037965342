.block--links {
	.link-resource-list {
		display: flex;
		flex-wrap: wrap;
	}

	.link-resource-container {
		display: flex;
		flex-wrap: wrap;
		flex: 1;

		padding-top: .755rem;
		padding-right: 0;
		padding-bottom: .755rem;

		@media (min-width: 925px) {
			flex: 0 50%;
			padding-top: 0;
			padding-right: .755rem;

			&:nth-child(even) {
				padding-right: 0;
			}
		}
	}

	.link-resource {
		display: inline-flex;
		flex-direction: column;
		width: 100%;
		border-radius: 3px;
		padding: 1.2rem;
		border: 1px solid #c9c9c8;
		box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);

		transition: all 0.24s cubic-bezier(0.215, 0.61, 0.355, 1);

		.delete-resource {
			display: none;
			cursor: pointer;
			margin-right: .55rem;

			&:last-child {
				margin-right: 0;
			}
		}

		.link-footer {
			display: flex;
			width: 100%;
			align-items: flex-end;
			margin-top: .55rem;

			.url {
				flex: 1;
				font-size: var(--size-14);
				word-break: break-all;

				a {
					text-decoration: none;
				}

				span.icon[name=External] {
					svg {
						width: var(--size-14);
						height: var(--size-14);
						margin-left: .35rem;
						position: relative;
						top: 1px;
					}
				}
			}
		}

		.link-content {
			display: flex;
			width: 100%;
			margin-top: .65rem;

			.image {
				width: 180px;
				height: 140px;
				margin-right: .65rem;
				background-position: center center;
				background-repeat: no-repeat;
				background-size: cover;
			}

			.content {
				flex: 1;
				margin-right: .65rem;
				font-size: var(--size-12);

				.title {
					font-size: var(--size-14);
					font-weight: 600;
					margin-bottom: .35rem;
				}

				.description {
					font-weight: 500;
				}
			}
		}
	}

	.c--button {
		display: none;
	}

	&.consume {
		a.link-resource {
			color:#3A3A3A;
			text-decoration: none;

			&:hover {
				box-shadow: 0 5px 18px rgba(0, 0, 0, 0.11);
			}
		}
	}
}

.editor-block.active {
	.block--links {
		.delete-resource {
			display: inline-block;
		}

		.c--button {
			display: inline-flex;
		}
	}
}