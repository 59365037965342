.reader {
	display: inline-flex;
	align-items: center;

	svg {
		width: 2rem;
		height: 2rem;

		fill: $color-meta-text;
	}

	&.trigger {
		color: $color-meta-text;
		font-size: $meta-text;
		cursor: pointer;
	}
}
