.facepile {
  overflow: hidden;
  display: flex;
  line-height: 0;
  align-items: center;
}

.facepile-extracount {
  font-size: 1.2em;
  margin-left: .55em;
  order: 2;
}

.facepile-faces {

}

.facepile-face {
  transition: margin .3s;
  display: inline-block;
  margin-left: -20px;
  float: right;

  width: 40px;
  height: 40px;
  border: 2px solid white;
  border-radius: 50%;
  overflow: hidden;

  &:last-child {
    margin-left: 0;
  }

  img {
    background: #ccc;
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    height: auto;
  }
}

.card:hover .facepile {
  .facepile-face:not(:last-child) {
    margin-left: -15px;
  }
}
