/**
* Cards container
* Displays cards in multiple columns based on screen width.
*/
.cards-container {
	margin-left: -10px;
	margin-right: -10px;

	/* Older browsers */ 
	display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
	display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
	display: -ms-flexbox;      /* TWEENER - IE 10 */
	display: -webkit-flex;     /* NEW - Chrome */

	/* Modern browsers */
	display: flex;
	flex-wrap: wrap;
	align-content: flex-start;

	&::before,
	&::after {
		content: '';
		display: table;
	}

	&::after {
		clear: both;
	}
}

.cards-container > .card-outer {
	-webkit-appearance: normal;
	padding-bottom: 10px;
	flex-shrink: 1;
	padding-right: 10px;
	padding-left: 10px;
	width: 100%;

	// 2 per row
	@include bp(54rem) {
		padding-bottom: 22px;
		width: calc(100% / 2.0033333);
		float: left;
	}

	// 3 per row
	@include bp(75rem) {
		width: calc(100% / 3.0033333);
		float: left;
	}
}

/**
* Card
*/
.card {
	background: #fff;
	border: 1px solid $border-color;
	box-shadow: 0 3px 15px rgba(0,0,0,0.03);

	// Default color, can be overwritten as component property
	border-left: 3px solid rgb(189, 189, 189);
	border-radius: $border-radius;
	min-height: 11.25rem;
	position: relative;
	padding: 1.25rem 1.15rem 1.25rem 1.15rem;
	color: inherit; // If card is anchor
	display: block; // If card is anchor
	text-decoration: inherit;

	.card_description {
		font-size: $regular-text;
		padding-top:0.3em;
		padding-bottom:0.3em;
		font-weight: 400;
	}

	.card_title {
		clear: both;
		font-weight: 400;
		font-size: $title-text;
		margin-top: .25em;
		margin-bottom: .25em;
	}

	.card_subtitle {
		font-size: $meta-text;
		font-weight:400;
	}

	.card-footer {
		position:absolute;
		width: 100%;
		left: 0;
		bottom: 0;
		z-index:1;
		margin:1em 0;
		padding-left: 1.25em;
	}

	.card-meta {
		font-size: $meta-text;
		font-weight:400;
		margin-bottom: .5em;
		background: rgb(238, 238, 238);
		border-radius: $border-radius;
		display: inline-block;
		padding: .25rem .5rem;
		margin-top: -.25rem;
		min-height:26px;

		svg {
			margin-right: 0.5em;
			width: 1.05em;
			height: 1.05em;
			vertical-align: -.2em;
		}
	}

	.card-meta.card-meta-alert {
		background: rgb(233, 104, 84);
		color: #fff;
	}

	/* Card type default border color for the left side */
	/* Planning card border in ReactGantt component */
	&.card-news {
		border-left-color: #EA7FB8;
	}

	&.card-task {
		border-left-color: #7FD3A3;
	}

	&.card-group {
		border-left-color: #5BA5E2;
	}
}

a.card {
	cursor: pointer;
}

a.card.card-group {
	min-height: 0;
	height:auto;
}

.card {
	transition: box-shadow .25s;
}
a.card:not(.card-placeholder):hover {
	box-shadow: 0 10px 20px rgba(100,100,100,0.09), 0 6px 6px rgba(100,100,100,0.13);
}

time.from-now, .card-meta {
	font-size: $meta-text;
	font-weight:400;
	margin-bottom: .5em;
	background: rgb(238, 238, 238);
	border-radius: $border-radius;
	display: inline-block;
	padding: .25rem .5rem;
	margin-top: -.25rem;

	svg {
		margin-right: 0.5em;
		width: 1.05em;
		height: 1.05em;
		vertical-align: -.2em;
	}
}

.card.card-placeholder {
	cursor: initial;

	.card_title:before,
	.card_subtitle:before,
	.card_description:before,
	.card_description:after {
		content: "";
		display: block;
		width: 100%;
		height: 1em;
		background: #f1f1f1;

		animation-duration: .6s;
		animation-name: blinkFade;
		animation-iteration-count: infinite;
		animation-direction: alternate;
	}

	.card_description:after {
		margin-top: .5em;
		width: 90%;
	}

	.card_title:before {
		width: 80%;
	}
	.card_subtitle:before {
		width: 40%;
	}
}

@keyframes blinkFade {
	from {
		opacity: 1;
	}
	to {
		opacity: .4;
	}
}
