.adjustment {
	.title {
		margin-bottom: 1.55rem !important;
	}

	.createdBy {
		margin-top: 0.45rem;
		font-size: 0.85rem;
	}

	.comment-form {
		margin-top: 3rem;

		// Comments list
		.comments {
			margin-bottom: 1rem;
		}

		// Single comment item
		.comment {
			margin-bottom: 0.95rem;
		}

		.input-container {
			flex: 1;
			margin-right: 0;
			display: flex;
			align-items: center;

			textarea {
				-webkit-appearance: text;
				-moz-appearance: text;
				appearance: text;
				resize: none;
				cursor: text;

				background-color: #EDEDED;
				width: 100%;
				color: #0F0F0F;
				border: 0;
				padding: 1.25em 2em;
				border-radius: 5px;
				font-size: 0.8rem;
				overflow: hidden;
				font-family: 'Open Sans', Arial;

				&:focus {
					-webkit-box-shadow: none;
					box-shadow: none;
					outline-style: none;
					outline: none;
				}

				&:disabled {
					cursor: not-allowed;
					opacity: 0.8;
				}
			}

			@media (min-width: 425px) {
				margin-right: 1.5rem;
			}
		}

		button {
			display: none;
			position: relative;
			background-color: #0079DB;
			color: white;
			border-radius: 5px;
			border: 0;
			padding: 1.25em 2em;
			cursor: pointer;
			font-size: 0.8rem;
			text-decoration: none;
			text-transform: uppercase;
			font-weight: 600;
			transition: all 0.24s cubic-bezier(0.215, 0.61, 0.355, 1);

			$spinner-size: 20px;

			.loading-container {
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				background-color: rgba(0, 0, 0, 0.25);

				.spinner-container {
					position: relative;
					top: 50%;
					margin-top: 0;
					transform: translateY(calc(-1 * #{$spinner-size} / 2));
				}

				.loading-spinner {
					width: $spinner-size;
					height: $spinner-size;
				}
			}

			&:disabled {
				cursor: not-allowed;
				opacity: 0.8;
			}

			@media (min-width: 425px) {
				display: block;
			}
		}
	}

	.side-info {
		margin-left: 0;
		padding-right: 0;

		@media (min-width: 425px) {
			margin-left: 3rem;
			padding-right: 2rem;
		}
	}

	.layout.flex {
		flex-direction: column;

		@media (min-width: 425px) {
			flex-direction: row;
		}
	}
}