
.filterContainer {
    width: 40%;
    margin-bottom: 2em;
}

.filterBox input {
    background-color: white;
    color: black;
    border: 1px solid #C9C9C9;
    border-radius: 3px;
    padding: 4px;
    font-size: 1em;
    font-weight: 400;
}
