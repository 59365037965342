.post-content {
	margin-top:0.5em;
	margin-bottom:0.5em;
	width: 100%;
	overflow:hidden;
	word-break: break-all;

	h2,
	h3,
	h4,
	p,
	ul,
	ol {
		margin-bottom: 1em;
	}

	ul, ol {
		padding-left: 2em;
	}

	iframe {
		border: none;
		width: 100%;
		display: block;
	}

	blockquote {
		border-left: 5px solid darken($border-color, 10);
		padding-left: 1em;
	}
}
