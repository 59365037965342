.site-footer {
	width: 100%;
	display: inline-block;
	vertical-align: bottom;

	.footer-content {
		text-align: center;
		margin: 0 auto;
		color: $color-disabled;
		font-size: 0.80em;
		padding-top: 2.5rem;
		padding-bottom: 2.5rem;
	}
}

.footer-menu-bar-container {
	background-color: white;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
	z-index: 99999;
	display: none;
	/* iPhone */
	padding-bottom: env(safe-area-inset-bottom);

	.menu-container {
		display: flex;
	}

	.menu-item-container {
		.menu-item {
			color: #696969;
			font-size: 0.5rem;

			position: relative;
			cursor: pointer;
			text-decoration: none;
			display: flex;
			flex-direction: column;
			padding: 0.5rem 0.8rem;
			height: 100%;
			justify-content: center;
			text-align: center;

			&.is-active {
				color: black;
			}

			.menu-item-icon {
				position: relative;

				svg {
					height: 1.25rem;
					width: 1.25rem;
				}

			}

			.badge {
				top: 3px;
			}

			&:after {
				height: 3px;
			}

			&:hover {
				background-color: white;
			}

			&.is-active {
				span {
					color: #0079DB;
				}

				.badge {
					display: none;
				}
			}
		}
	}
}

/* Display rules */
html.is-device {
	.footer-menu-bar-container {
		display: flex;
	}
}

body.is-frame {
	.site-footer {
		position: static;

		.footer-content {
			padding-top: 5rem;
			padding-bottom: 0;
		}
	}
}