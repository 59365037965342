$breakpoint: 830px;

.row-group {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-right: 1rem;
}

.card-meta {
	align-self: flex-start;
}

/* Media query for mobile devices */
@media (max-width: $breakpoint) {
	.row-group {
		flex-direction: column;
		font-size: 1.0rem;

		.radiobutton .check {
			height: 22px !important;
			width: 22px !important;

			.indicator {
				height: 9px !important;
				width: 9px !important;
			}
		}
	}

	.card-meta {
		margin-left: 30px !important;
		margin-top: 0.2rem !important;
	}
}