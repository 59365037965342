.login-content {
  max-width: 41rem;
  margin: 3rem auto 0;
  padding: 9.25% 2em 0;

  h1 {
    font-size: 2em;
    line-height: 1.16;
    margin-bottom: .35em;
  }

  p {
    color: #828282;
  }

  &__subtitle {
    margin-bottom: 1.5em;
    font-size: 1.0625em;
    font-weight: 300;
  }

  &__helptext {
    font-size: .8125em;
    font-weight: 400;
    margin-top: 1em;

  }

  @media screen and (min-width: 40rem) {
    h1 {
      font-size: 3.5em;
      line-height: 1.16;
      margin-bottom: .35em;
    }
    &__subtitle {
      margin-bottom: 3.5em;
    }
  }


}
