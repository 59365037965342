.planning {
	.card-outer {
		width: 100%;

		.card-planning {
			min-height: 0;
			height: auto;

			.task-meta-container {
				float: right;
			}
		}
	}

	.current-column {
		background: $color-primary;
		color: white;
		padding: .65em 1.25em;
		border-radius: 2em;
	}

	.timeline-planning-title {
		width: 100%;
		float: left;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-top: 0.5em;
		margin-bottom: 0.5em;
		font-weight: 400;
		font-size: 1.25em;
	}

	.time-line-item-date {
		white-space: nowrap;
	}
}

.timeline-dropdown-button {
	border-radius: 50px;
	border: 1px solid #E4E4EB;
	display: inline-block;
	padding: 0.4em;
	line-height: 0.4em;
	text-align: center;
	padding-top: 0.5em;
	padding-left: 0.45em;
	margin-left: 0.5em;
	position: relative;
	top: 2px;

	svg {
		width: 15px;
		height: 15px;
	}
}

.lines {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 0;

	.line {
		width: 25%;
		height: 100%;
		border-left: $get_the_border;
		float: left;
		position: relative;
	}
}

.gantt-container {
	table {
		tbody {
			position: relative;
			z-index: 1;
		}
	}
}

.time-line-item-titles {
	position: relative;
	width: 100%;
	height: 20px;
	display: none;

	.time-line-item-groups {
		flex: 1;

		.dots-container {
			padding-top: .25em;
			padding-bottom: .25em;
		}
	}

	.timeline-item-title {
		.timeline-item-color {
			content: "";
			display: inline-block;
			background-color: #feb;
			border: 2px solid white;
			height: 20px;
			width: 20px;
			border-radius: 20px;
			vertical-align: sub;
			margin-right: .2em;
		}
	}

	.card-meta {
		padding: .25em .5em;
		margin: 0;
	}

	&::after {
		content: ' ';
		display: block;
		clear: both;
	}

	@include bp(small) {
		display: flex;
	}
}

.single-plan {
	.block {
		.expandable-header {
			border: 0 !important;

			.title {
				padding: 0 !important;
				margin-bottom: 0 !important;
			}

			.trigger-container {
				padding: 0 !important;
			}

			&.open {
				box-shadow: none !important;
			}
		}

		.expandable-content {
			padding: 0 !important;
		}

		.expandable-content.open {
			background: none !important;
			border: 0 !important;
			margin-top: 1rem !important;
		}
	}

	.courses {
		.course {
			display: flex;
			align-items: center;
			margin-top: .2rem;

			.indicator {
				width: 20px;
				height: 20px;
				border-radius: 10px;
				background-color: #ccc;
				margin-right: .45rem;
			}

			&:first-child {
				margin-top: 0;
			}
		}
	}
}

/**
 * title Styles
 */

/* Add this attribute to the element that needs a title */
[data-title] {
	position: relative;
	cursor: pointer;
}

/* Hide the title content by default */
[data-title]:before,
[data-title]:after {
	visibility: hidden;
	opacity: 0;
	pointer-events: none;
}

/* Position title above the element */
[data-title]:before {
	position: absolute;
	top: 150%;
	left: 50%;
	margin-bottom: 5px;
	transform: translateX(-50%);
	padding: 7px;
	width: auto;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	background-color: #000;
	color: #fff;
	content: attr(data-title);
	text-align: center;
	font-size: 14px;
	line-height: 1.2;
}

/* Triangle hack to make title look like a speech bubble */
[data-title]:after {
	position: absolute;
	top: 150%;
	left: 50%;
	margin-left: -5px;
	width: 0;
	margin-top: -5px;
	border-bottom: 5px solid #000;
	border-right: 5px solid transparent;
	border-left: 5px solid transparent;
	content: " ";
	font-size: 0;
	line-height: 0;
}

/* Show title content on hover */
[data-title]:hover:before,
[data-title]:hover:after {
	visibility: visible;
	opacity: 1;
}