.dropdown {
	position: relative;
	display: inline-block;

	.dropdown-menu {
		position: absolute;
		line-height: 2;
		top: 100%;
		left: 0;
		text-align: left;
		z-index: 1000;
		min-width: 14rem;
		background-color: #fff;
		border: $border-width solid $border-color;
		border-radius: $border-radius;

		.dropdown-item {
			cursor: pointer;
		}
	}

	svg {
		width: .75em;
		height: .75em;
		margin-left: .3em;
	}

	.drop-down {
		cursor: pointer;
	}

	.drop-down-icon {
		position: relative;
		vertical-align: middle;
		display: inline;
		margin-right: .6em;

		svg {
			height: 1.5rem !important;
			width: 1.5rem !important;
		}
	}

}

.dropdown.dropdown-left {
	.dropdown-menu {
		right: 0;
		left: auto;

		h4 {
			padding-left: .8em;
		}
	}
}

.dropdown-menu {
	display: block;

	.dropdown-menu-container {
		width: 100%;

		.dropdown-menu-item {
			display: inline-block;
			cursor: pointer;
			position: relative;

			color: #363636;

			text-decoration: none;
			width: 100%;
			padding: 0.4rem 0;

			.active-color {
				display: inline-block;
				width: .625rem;
				height: .625em;
				margin-right: .625em;
				border-radius: 50%;
			}

			span {
				display: block;
				width: 100%;
				padding: 0 1.5em;
				height: 100%;
			}

			a {
				color: #363636;
				text-decoration: none;
			}

			&:hover {
				background-color: #f9f9f9;
			}
		}
	}

	.divider {
		background: #E4E4EB;
		width: calc(100% - 3em);
		height: 1px;
		display: block;
		margin: 0.8rem 1.5em;
	}
}

.badge {
	position: absolute;
	top: 0;
	right: 0;
	transform: translateY(-72%) translateX(50%);
	border-radius: 100%;
	color: white;
	width: 1.6rem;
	height: 1.6rem;
	display: flex;
	align-items: center;
	border: 2px solid #fff;
	// badge background
	background: rgb(255, 128, 128);
	background: -moz-linear-gradient(133deg, rgba(255, 128, 128, 1) 0%, rgba(230, 62, 133, 1) 100%);
	background: -webkit-linear-gradient(133deg, rgba(255, 128, 128, 1) 0%, rgba(230, 62, 133, 1) 100%);
	background: linear-gradient(133deg, rgba(255, 128, 128, 1) 0%, rgba(230, 62, 133, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff8080", endColorstr="#e63e85", GradientType=1);

	span {
		font-size: 0.8rem;
		position: relative;
		text-align: center;
		width: 1.6rem;
	}
}