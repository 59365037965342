.error-messages-container {
	width:100%;
	position: fixed;
	top:0;
	z-index: 2147483649;

	.error {
		width:100%;
		margin:0 auto;
		transition:all $animation-cubic-short;
		border-bottom-left-radius:3px;
		border-bottom-right-radius:3px;

		.content {
			max-width: 65rem;
			padding: 0.8em 1.25rem;
			margin: 0 auto;
		}

		&.type-warning {
			color: white;
			background-color: #EFC24C;
		}

		&.type-info {
			color: white;
			background-color: #73D266;
		}

		&.type-notice {
			color: white;
			background-color: #68B1EF;
		}

		&.type-error {
			color: white;
			background-color: #F25252;
		}

		&.not-visible {
			display: none;
		}

		&.is-visible {
			display: block;
		}
	}
}