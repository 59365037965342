.form-container {
	position: relative;
	max-height: 100%;

	&.widget-assessment {
		margin-top: 25px;

		.form-row {
			label {
				margin-top: 0;
			}
		}
	}
}

.no-section-found-create-section {
	background-color: white;
	padding: 1rem;
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.07);
	border-radius: 10px;
}

.form {

	/* Form container */
	.form-validation {
		margin-top: 0.5em;

		.text-danger {
			color: red;
		}
	}

	.form-row-half {
		width: 100%;

		@include bp(medium) {
			width: 49%;
			float: left;
		}

		&.static {
			width: 49%;
			float: left;
		}

		&.last {
			float: right;
		}
	}

	.form-row {
		position: relative;
		width: 100%;
		display: block;
		clear: both;

		color: black;
		font-size: 1em;

		&.spacing {
			margin: 25px 0;
		}

		&.submit {
			button {
				width: 100%;
				margin-top: 1em;

				@include bp(tablet) {
					width: auto;
					margin-top: 0;
					margin-right: 20px;
				}

				&:last-child {
					margin-right: 0;
					margin-left: 0;
				}

			}
		}

		label {
			margin-top: 30px;
			margin-bottom: 8px;
			display: block;
			font-size: 1.125em;
			font-weight: 500;
		}

		span.title {
			font-size: 1.1em;
		}

		input[type=text],
		input[type=time],
		input[type=number] {
			background-color: white;
			color: black;
			border: $get_the_input_border;
			border-radius: 3px;
			width: 100%;
			font-family: 'Open Sans', Arial;
			padding: 15px;
			font-size: 1em;
			font-weight: 400;
			min-height: 60px;

			appearance: textfield;
			-moz-apperance: textfield;
			-webkit-appearance: textfield;

			&::-webkit-inner-spin-button,
			&::-webkit-outer-spin-button {
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				margin: 0;
			}
		}

		input[type=time]::-webkit-clear-button {
			display: none;
		}

		textarea {
			all: unset;
			-webkit-appearance: none;
			-moz-appearance: none;
			resize: none;
			box-sizing: border-box;
			background-color: white;
			color: black;
			border: $get_the_input_border;
			border-radius: 3px;
			width: 100%;
			padding: 15px;
			font-size: 1em;
			font-weight: 400;
			height: 135px;
			white-space: pre-wrap;
		}

		.align-right {
			float: none;

			@include bp(tablet) {
				float: right;
			}
		}

		.react-datepicker__input-container {
			width: 100%;

			input {
				background-color: #F9F9F9;
				border: 1px solid #C9C9C9;
			}
		}
	}

	.expandable-header {
		border-radius: 0 !important;
	}

	.expandable-content {
		.expandable-header {
			border-radius: 5px !important;
		}
	}

	.group-assignment-selector {
		label {
			margin: 0;
		}
	}

	.add-additional-group {
		float: left;
		background-color: #F9F9F9;
		text-align: center;
		cursor: pointer;
		border-radius: 4px;
		padding: 0.55em;
		margin-right: 0.5em;

		span.helper {
			color: black;
			margin-right: 0.4em;
		}

		span.plus {
			font-weight: 600;
			color: #cfcfcf;
		}
	}

	.form-divider {
		background: #E4E4EB;
		width: 100%;
		height: 1px;
		display: block;
		margin-top: 40px;
	}

	.conditional-groups-container {
		float: left;
	}

	.group-editor-container {
		width: 100%;
		float: left;

		@include bp(tablet) {
			float: right;
			width: auto;
		}

		.create-count-groups {
			margin-top: 1em;
			display: block;
			width: 100%;

			@include bp(tablet) {
				float: left;
				margin-right: 1em;
				margin-top: 0em;
				width: auto;
			}
		}
	}

	.sortable-list {
		width: 100%;
		display: block;

		.list-item {
			padding: 1.3em 1em;
			background-color: white;
			width: calc(100%/3);
			float: left;

			.drag-area {
				display: inline-block;
				float: right;
				cursor: pointer;
				height: 22px;

				.dragging-icon {
					height: 100%;
					width: 27px;
					background: url(../img/draggable-icon.png);
					background-repeat: no-repeat;
					background-size: contain;
					background-position: center center;
				}
			}

			.item-name {
				display: inline-block;
			}

			&:hover {
				background-color: #F3F3F3;
			}
		}

		.list-outer-container {
			margin-bottom: 1.5em;

			&:last-child {
				margin-bottom: 0;
			}

			.list-title-container {
				margin-bottom: 0.8em;

				@include bp(medium) {
					margin-bottom: 0;
				}

				input {
					border: 0;
					border-bottom: $get_the_border;
					box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
					font-weight: 500;
				}

				.list-title-text {
					width: 100%;
					padding: 15px;
					font-size: 1em;
					line-height: 30px;
					border-bottom: $get_the_border;
					box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
					display: block;
				}
			}
		}

		.list-container {
			background-color: white;
			border: $get_the_border;
			border-radius: 3px;
			min-height: 177px;
			margin-bottom: 0.8em;

			@include bp(medium) {
				margin-bottom: 0;
			}
		}

		&.group-assignment {
			.list-outer-container {
				width: 100%;
				margin: 0;
				display: block;
				float: left;

				.list-item {
					width: 100%;
				}

				&:last-child {
					margin-right: 0;
					display: block;
					clear: both;
					width: calc(100%/3 - 1.4em);
				}

				@include bp(medium) {
					width: calc(100%/3 - 1.4em);
					margin: 0.7em 0;
					margin-right: 0.7em;
				}
			}
		}

		.source-preview {
			position: absolute;
			background-color: white;
			padding: 1em;
			border: 1px solid #EEEEEE;
			border-radius: 3px;
			opacity: 1 !important;
			width: 100%;
			max-width: 65rem;
		}

	}
}

.select {
	select {
		-moz-appearance: none;
		-webkit-appearance: none;
		border: 0;
		box-shadow: 0;
		width: 100%;
		border-radius: 3px;
		border: $get_the_input_border;
		background: url("../img/br_down.png") no-repeat;
		background-position: calc(100% - 1em) center;
		background-size: 10px;
		background-color: #F9F9F9;
		padding: 20px 16px;
		font-size: 0.9em;
		font-weight: 500;
	}
}

/* ADJUST THE REACT DATEPICKER STYLE */
.react-datepicker-popper {
	z-index: 3463784;
	margin-top: 20px;
}

.react-datepicker {
	border: 1px solid #E4E4EB !important;

	.react-datepicker__triangle {
		display: none !important;
	}

	.react-datepicker__navigation {
		top: 29px;

		&.react-datepicker__navigation--previous {
			border-right-color: black;
			background-color: #f9f9f9;
		}

		&.react-datepicker__navigation--next {
			border-left-color: black;
			background-color: #f9f9f9;
		}
	}

	.react-datepicker__month-container {
		background-color: #F9F9F9 !important;
		padding: 0.4em !important;

		.react-datepicker__header {
			background-color: #F9F9F9 !important;
			border: 0 !important;

			.react-datepicker__current-month {
				padding-top: 10px;
				padding-bottom: 10px;
				color: #838383 !important;
			}
		}
	}

	.react-datepicker__day-names {
		padding-top: 1.25em;

		.react-datepicker__day-name {
			color: #B2B2B2 !important;
			text-transform: uppercase;
			margin: 0 !important;
			padding: 0.265em !important;
			width: 2.5rem;
		}
	}

	.react-datepicker__day {
		border-radius: 0;
		margin: 0 !important;
		padding: 0.166rem;
		font-weight: 500;
		width: 2.5rem;

		&.react-datepicker__day--in-range {
			background-color: #EBEBEB !important;
			color: black;
		}

		&.react-datepicker__day--in-selecting-range {
			background-color: #F2F2F2 !important;
			color: black;
		}

		&.react-datepicker__day--range-start {
			position: relative;
			z-index: 9;
			background-color: #3A3A3A !important;
			color: white;
			border-radius: 3px !important;
		}

		&.react-datepicker__day--today {
			font-weight: 700 !important;
		}

		&.react-datepicker__day--range-end {
			position: relative;
			z-index: 9;
			background-color: #3A3A3A !important;
			color: white;
			border-radius: 3px !important;
		}

		&:hover {
			background-color: #CBCBCB !important;
			border-radius: 3px !important;
		}

		&.react-datepicker__day--disabled {

			&:hover {
				background-color: #F9F9F9 !important;
			}
		}
	}
}

.is_sending {
	width: 100%;
	height: calc(100% + 7em);
	position: absolute;
	left: 0;
	z-index: 9999999999;
	background: rgba(255, 255, 255, 0.75);

	p {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
	}
}

.loading-spinner {
	width: 80px;
	height: 80px;
	margin-bottom: 1em;
	position: relative;
	display: inline-block;
	background: url('../img/spinner.png');
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;

	-webkit-animation-name: spin;
	-webkit-animation-duration: 850ms;
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-timing-function: linear;

	-moz-animation-name: spin;
	-moz-animation-duration: 850ms;
	-moz-animation-iteration-count: infinite;
	-moz-animation-timing-function: linear;

	-ms-animation-name: spin;
	-ms-animation-duration: 850ms;
	-ms-animation-iteraton-count: infinite;
	-ms-animation-timing-function: linear;

	animation-name: spin;
	animation-duration: 850ms;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
}

.spinner-container {
	margin: 0;

	&.center {
		margin: 0 auto;
		text-align: center;
		width: 100%;
		margin-top: 2em;
	}

	&.right {
		margin: 0 auto;
		text-align: right;
		width: 100%;
	}

	&.small {
		.loading-spinner {
			height: 40px;
			width: 40px;
		}
	}
}

.c--button {
	position: relative;

	.spinner-container {
		display: flex;
		width: 100%;
		height: 100%;
		background-color: #005da9;
		position: absolute;
		top: 0;
		left: 0;
		align-items: center;
		justify-content: center;
		margin-top: 0 !important;
		border-radius: 5px;

		.loading-spinner {
			position: relative;
			margin: 0;
			height: 27px !important;
			width: 27px !important;
		}
	}

	&.secondary {
		.spinner-container {
			background-color: white;
		}
	}
}

@-moz-keyframes spin {
	from {
		-moz-transform: rotate(0deg);
	}

	to {
		-moz-transform: rotate(360deg);
	}
}

@-webkit-keyframes spin {
	from {
		-webkit-transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}