.swal2-container {
	z-index: 9999999999999 !important;

	.swal2-modal {
		border: 1px solid #DBDBDB;
		border-radius: 10px;
		box-shadow: 0 5px 15px rgba(0,0,0,0.05);
		font-weight: 400 !important;
		padding: 35px;
		color: #3A3A3A;
		z-index: 9999999999999 !important;

		.swal2-title {
			width: 100%;
			text-align: left !important;
			font-weight: 500 !important;
			font-size: 25px;
			margin-top:0 !important;
			padding:0;
			margin-bottom:35px;
			color:#3A3A3A;
		}

		.swal2-text {
			display: block;
			padding:0;
			padding: 0;
			text-align: left;
			width: 100%;
			margin-top:0 !important;
		}

		.swal2-content {
			padding:0;
			margin-top: 5px;
			text-align:left;
			font-weight: 400 !important;

			input {
				border:1px solid #DBDBDB;
				border-radius:4px;
				padding:19px;
				color:#3A3A3A;
			}

			#swal2-content {
				font-weight: 400 !important;
				text-align: left !important;
				color:#3A3A3A;
			}
		}

		.swal2-actions {
			display: block;
			padding: 0;
			width:100%;
			display: flex;

			button {
				width:100%;
				border: $get_the_border !important;
				border-radius: $button-border-radius !important;
				color:white;
				text-decoration: none;
				text-transform: uppercase;
				padding: 15px 16px;
				font-weight: 700;
				font-size: 13px;

				&:first-child {
					margin-left: 0;
				}

				&:last-child {
					margin-right: 0;
				}
			}

			button.swal2-confirm {
				background-color:$color-primary !important;
				border-color:$color-primary !important;

				&:hover:enabled {
					background-color: $color-primary-hover !important;
					border-color: $color-primary-hover !important;
				}

				&:active {
					background-color: $color-primary-active !important;
					border-color: $color-primary-active !important;
				}
			}

			button.swal2-cancel {
				background-color: $color-secondary !important;
				color: #3A3A3A;
				letter-spacing: 1px;
				font-weight: 500;

				&:hover {
					background-color: $color-secondary-hover !important;
				}

				&:active {
					background-color: $color-secondary-active !important;
				}
			}
		}
	}
}
