.meeting {
	&.single-instance {
		.notes {
			.layout {
				margin-bottom: 1.25rem;
			}

			.note {
				margin-bottom: 1.25rem;
				border-radius: 3px;
				box-shadow: 1px 5px 15px 0 rgba(0, 0, 0, 0.05);
				border: solid 1px #dbdbdb;
				padding: 1.55rem 1.25rem;

				h3 {
					margin-top: 0;
					margin-bottom: 0.35rem;
					font-size: 1rem;
					font-weight: 500;
				}

				.creator {
					color: rgb(159, 159, 159);
					font-weight: 500;
					font-size: 0.8rem;
					margin-top: 0.55rem;

					svg {
						width: 0.9rem;
						position: relative;
						top: 1px;
					}
				}

				&:last-child {
					margin-bottom: 0;
				}

				&:hover {
					box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
				}

				// end .note
			}

			// end .notes
		}

	}

	.forms {
		.form {
			margin-bottom: 1.25rem;
			border-radius: 3px;
			box-shadow: 1px 5px 15px 0 rgba(0, 0, 0, 0.05);
			border: solid 1px #dbdbdb;
			padding: 1.55rem 1.25rem;
			cursor: pointer;

			.form-title {
				font-size: 1.2rem;
			}

			&:hover {
				box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
			}
		}
	}

	.meeting-forms {
		margin-left: -1.7rem;
		margin-right: -1.7rem;

		.form-item {
			cursor: pointer;
			border-bottom: 1px solid #f0f0f0;
			padding: 1.25rem 0;
			display: flex;
			flex-wrap: wrap;

			.cell {
				width: calc(67%/3);
				align-items: center;
				flex-grow: 0; -webkit-flex-grow: 0;
				flex-shrink: 0; -webkit-flex-shrink: 0;
				vertical-align: top;
				padding: 0 1.7rem;

				&.title {
					width: 33%;
					font-weight: 500;
				}
			}

			&:hover {
				background-color: #f0f0f0;
			}
		}

		@media (max-width: 920px) {
			.form-item {
				flex-direction: column;

				.cell {
					width: 100% !important;
				}
			}
		}
	}
}
