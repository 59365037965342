.preschooldocuments {
	.section-header {
		width: 100%;
		margin: 0.9em 0;

		h2 {
			float:left;
			font-size: 1.625em;
			font-weight: 500;
		}

		.create-container {
			float:right;
			font-size: 1.25em;

			button {
				left:0;
				margin:0;
			}
		}

		&:after {
			content: '';
			width:100%;
			display:block;
			clear: both;
		}
	}
}

.posts-section-container {
	.posts-tools {
		float:right;
		width:calc(30% - 2em);

		.tool-item {
			cursor: pointer;
			cursor: pointer;
			padding: 0.7em 0.5em;
			border-radius: 5px;
			float: left;
			clear: both;

			&:hover {
				background-color: #E5E5E5;
			}
		}
	}
}
