.show-more-posts {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	color: #3a3a3a;
	background-color: #fff;
	margin: 2em auto;
	box-shadow: 0 5px 15px #0000000d;
	border: 1px solid #c9c9c9;
	border-radius: 5px;
	padding: .8rem 1.35rem;
	font-size: 14px;
	cursor: pointer;

	&:hover {
		background-color: #f5f5f5 !important;
	}
}

.post-button-container {
	display: flex;
	justify-content: flex-end;
	flex-direction: row;
	align-items: center;
	width: 100%;
	padding: 0 10px 0 10px;

	@media (max-width: 650px) {
		align-items: flex-end;
		flex-direction: column;
	}

	.show-only-unreads {
		@media (max-width: 650px) {
			margin-bottom: 1rem;
		}

		.checkbox {
			font-size: 14px;
			display: inline-flex;
			align-items: center;
			justify-content: center;
		}
	}

	button {
		margin-bottom: 1rem;
		margin-left: 2rem;
	}

	.c--floating {
		padding-left: 10px;
	}
}