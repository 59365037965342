@import "~@haldor/ui/dist/variables.scss";
@import "~@haldor/ui/dist/definitions.scss";

@import 'partials/fonts';
@import 'partials/breakpoints';
@import 'partials/variables';
@import 'partials/tooltip';

@import 'vendor/react-datepicker';
@import 'vendor/swal';

@import 'components/footer';
@import 'components/card';
@import 'components/section';
@import 'components/task';
@import 'components/latest';
@import 'components/navbar';
@import 'components/dropdown';
@import 'components/login';
@import 'components/menuItem';
@import 'components/content';
@import 'components/titles';
@import 'components/alert';
@import 'components/form';
@import 'components/upload';
@import 'components/matrix-block';
@import 'components/graphs';
@import 'components/modal';
@import 'components/news';
@import 'components/facepile';
@import 'components/attached_documents';
@import 'components/schedule_section';
@import 'components/error';
@import 'components/single_task';
@import 'components/planning';
@import 'components/proficiency';
@import 'components/pupil_list';
@import 'components/student_assessment_list';
@import 'components/detailsTable';
@import 'components/table';
@import 'components/loadingpage';
@import 'components/block';
@import 'components/documents';
@import 'components/preschool_groups';
@import 'components/colordots';
@import 'components/core_content';
@import 'components/feedback-block';
@import 'components/reader';
@import 'components/new_card';

@import 'components/select';

@import 'components/editor/editor';
@import 'components/editor/post-content';

@import 'components/time_picker';
@import 'components/expandable';

@import 'icons/icons';

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	width: 100%;
	height: 100%;
}

.clearfix {
	clear: both;
}

body {
	position: relative;
	width: 100%;
	height: 100%;
	background-color: #f5f5f5;
	font-family: 'Open Sans', Arial;
	line-height: 1.4;
	color: rgb(54, 54, 54);
}

a {
	color: #5aa4ce;
}

.cl-container {
	text-align: center;
	left: 0;
	margin: 0 auto;
	height: 15px;
	width: 15px;

	.cls-1 {
		fill: none;
		stroke: black;
		stroke-linecap: round;
		stroke-linejoin: round;
		stroke-miterlimit: 10;
		stroke-width: 2px;
	}

	.cls-4 {
		fill: #C8C8D1;
	}

	.a-01 {
		fill: none;
		stroke: #838383;
		stroke-linecap: round;
		stroke-linejoin: round;
		stroke-miterlimit: 10;
		stroke-width: 2px;
	}

	svg {
		&.i-90 {
			transform: rotate(-90deg) translateX(20%);
			position: relative;
			top: -2px;
			left: 2px;
		}

		&.a-90 {
			transform: rotate(90deg) translateX(-20%);
		}

		&.i-45 {
			transform: rotate(-45eg);
			position: relative;
			top: -2px;
		}

		&.a-45 {
			transform: rotate(45deg);
		}

		&.a-180 {
			transform: rotate(180deg);
		}
	}
}

.section {
	margin-top: 3.2em;
	padding-top: 1.9em;
	border-top: 1px solid $color-outline;

	&:first-child {
		border-top: 0;
		padding-top: 0;
	}
}

.test_planning {
	p {
		padding: 1.05em 1.05em;
		margin: 2em;
		background: #fefefe;

		i {
			color: $color-meta-text;
		}
	}

	hr {
		width: 100%;
		height: 1px;
		margin-top: 1em;
		border: 0;
		margin-bottom: 1em;
		background-color: $border-color;
	}

	.icons {
		svg {
			height: 28px;
			width: 28px;
		}
	}
}

.pull-right {
	@include bp(medium) {
		float: right;
	}
}

.pull-left {
	float: left;
}

@media print {

	.no-print,
	.no-print * {
		display: none !important;
	}
}

html.is-phone {
	.page {
		padding: 0px 10px;
	}
}

.c--layout {
	min-height: calc(100% - 4.15rem);

	.c--content {
		margin-right: 2.55rem;
	}
}

#root {
	height: 100%;
}

#root>[data-reactroot] {
	height: 100%;
}

.c--sider {
	.menu {
		padding-top: 1.25rem;

		.cbp-tm-menu>li {
			display: block !important;
		}
	}

	.menu-item {
		.menu-icon-container {
			top: 2px;
		}
	}
}

html.is-device {
	main.c--layout {
		flex-wrap: wrap;
		padding-top: 0;
		height: auto;

		.c--sider {
			display: none;
		}

		.c--content {
			margin-top: 0;
		}
	}

	.c--content {
		margin-right: 0;
	}

	.overview,
	.single-task {
		padding-left: 10px;
		padding-right: 10px;
	}

	.haldor-app {
		padding-bottom: 60px; // Add space at bottom so the footer doesnt cover content
	}

	#root {
		overflow: auto;
		height: auto;
	}

	#root>[data-reactroot] {
		height: auto;
	}
}

.page {
	.create-button-container {
		display: block;
		margin-bottom: 1rem;

		.c--button {
			width: 100%;
		}

		@include bp(medium) {
			.c--button {
				width: auto;
			}
		}
	}
}

//Markup with red dot/badge without number for unread post/documentation/event
.unread-container {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: .5rem;

	.unread-dot {
		background: linear-gradient(133deg, rgb(255, 128, 128) 0%, rgb(230, 62, 133) 100%);
		width: 14px; 
		height: 14px;
		border-radius: 50%;
	}
}