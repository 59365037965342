.block--assignment-block {
	.assignment-resource {
		display: block;
		background-color: white;
		border: 1px solid #c8c9c8;
		width: 100%;
		margin-bottom: 0.755rem;
		padding: 1.65rem;
		border-radius: 5px;
		text-decoration: none;
		color: inherit;
		border-left-width: 3px;
		border-left-color: #7FD3A3;
		box-shadow: 0 3px 15px rgba(0,0,0,0.03);
		transition: box-shadow 0.24s cubic-bezier(0.215, 0.61, 0.355, 1);

		.layout.flex {
			flex-direction: column;
		}

		span.title {
			font-size: 1.15rem;
		}

		.card-meta {
			font-size: var(--size-14) !important;
			margin: 0;
			margin-top: 5px;
			margin-right: 5px;

			&:last-child {
				margin-right: 0;
			}
		}

		.badge-primary {
			font-size: var(--size-14) !important;
		}

		.assignment-meta {
			margin-top: 1rem;
		}

		@media (min-width: 845px) {
			.card-meta {
				margin-top: 0;
			}

			.submission-info {
				margin-top: 1rem;
			}

			.assignment-meta {
				margin-top: 0;
				text-align: right;
			}

			.layout.flex {
				flex-direction: row;
				align-items: center;
			}
		}

		.delete-resource {
			display: none;
			cursor: pointer;
			position: relative;
			top: 2px;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	.c--button {
		display: none;
	}

	&.consume {
		a.assignment-resource {
			&:hover {
				box-shadow: 0 10px 20px rgba(100,100,100,0.09), 0 6px 6px rgba(100,100,100,0.13);
			}
		}
	}
}