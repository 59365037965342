.device-menu {
	background-color: white;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.45rem 0.6rem;
	margin-top: env(safe-area-inset-top);
	flex-direction: column;

	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	z-index: 1000;

	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);

	.right {
		height: 41px;
		width: 41px;
	}

	.c--button.back {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding: 0;
		background-color: none;
		border: 0;
		font-size: 0.65rem;
		letter-spacing: normal;
		flex: 0;
		border-radius: 50%;
		padding-right: 0.55rem;

		&:hover,
		&:active,
		&:focus {
			background-color: #e7e7e6;
		}
	}

	.cl-container {
		width: 1rem;
		height: 1rem;
		left: none;
		margin: 0;
	}

	svg {
		width: 1rem;
		height: 1rem;
		left: -4px;
		top: 1px;
		position: relative;
	}

	.content {
		display: flex;
		justify-content: flex-end;
		flex: 1;
		width: 100%;
		> div {
			display: flex;
			align-items: flex-end;
		}

		span.icon {
			margin-left: 0.25rem;

			svg {
				top: 5px;
				left: 0;

				width: 1.3rem !important;
				height: 1.3rem !important;
			}
		}
	}

	.left {
		display: flex;
		align-items: center;
		flex: 1;
		width: 100%;
		font-size: 1.25rem;
		font-weight: 500;
	}

	.right {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.device-menu-space {
	position: fixed;
	background-color: white;
	width: 100%;
	height: env(safe-area-inset-top);
	z-index: 912431421;
}

.device-menu-placeholder {
	display: block;
	height: 5.626rem;
	width: 100%;
}

.device-menu-placeholder-iphone {
	display: block;
	height: 8rem;
	width: 100%;
}

.menu-item {

	.badge-label {
		margin: 0 !important;

		.badge {
			margin: 0 !important;

		}

		.icon {
			margin-left: 0.4rem !important;
		}
	}
}