.simple-select {

  &__color {
    display: inline-block;
    width: .625rem;
    height: .625em;
    margin-right: .625em;
    border-radius: 50%;
  }

  .Select-control {
    border: $border-width solid $border-color;
    border-color: transparent;
    cursor: pointer;
  }

  .Select-arrow {
    opacity: 0;
  }

  &:hover .Select-control {
    border-color: $border-color;
  }

  &:hover .Select-arrow {
    opacity: 1;
  }
}

