.collapsible {
	.collapsible-trigger {
		cursor: pointer;

		svg {
			width: 1.65rem;
			height: 1.65rem;
			transition: all 0.27s cubic-bezier(0.215, 0.61, 0.355, 1);
			transform: rotate(0deg);
			position: relative;
			left: -3px;
			top: 2px;
		}
	}

	.collapsible-content {
		height: 0;
		overflow: hidden;
		transition: all 0.32s cubic-bezier(0.215, 0.61, 0.355, 1);
	}

	// When the Collapsible is open
	&.open {
		.collapsible-trigger {
			svg {
				transform: rotate(180deg);
			}
		}
	}
}
