.ql-editor {
	min-height: 10rem;
	font-size: 16px;
}

.quill {
	margin-bottom: 30px;
	border-color: red;
}

.ql-container {
	border-color: $input-border-color !important;
	border-bottom-left-radius: $border-radius;
	border-bottom-right-radius: $border-radius;
}

.ql-toolbar {
	border-color: $input-border-color !important;
	border-top-left-radius: $border-radius;
	border-top-right-radius: $border-radius;
}
