.outcomes {
	color: #3a3a3a;
	font-size: var(--size-12);

	.rubric {
		display: flex;
		flex-direction: column;
		margin-bottom: 1.35rem;
		padding-bottom: 1.35rem;
		border-bottom: 1px solid #e4e4eb;

		.rubric-content {
			display: flex;
			flex-direction: column;

			@media (min-width: 985px) {
				flex-direction: row;
			}
		}

		.description {
			margin-bottom: 1rem;
			flex-basis: 100%;

			@media (min-width: 845px) {
				margin-right: 2rem;
				flex-basis: 20%;
			}
		}

		.levels {
			display: flex;
			flex: 1;
			flex-direction: column;

			.level {
				border-radius: 4px;
				background-color: #F6F6F6;
				margin-bottom: 0.65rem;
				padding: 1rem;
				flex: 1;

				strong {
					display: block;
					margin-bottom: 0.55rem;
				}

				&.marked {
					background-color: #005da9;
					color: white;
				}

				&:last-child {
					margin-right: 0;
					margin-bottom: 0;
				}

				@media (min-width: 845px) {
					margin-bottom: 0;
					margin-right: 0.65rem;
				}
			}

			@media (min-width: 845px) {
				flex-direction: row;
			}
		}

		&:last-child {
			margin-bottom: 0;
			padding-bottom: 0;
			border-bottom: 0;
		}
	}

	.rubric-feedback {
		margin-top: 0.75rem;

		strong {
			display: block;
			margin-bottom: 0.35rem;
		}
	}
}