.upload-wrapper {
	position:relative; 

	.upload-container {
		width:100%;
		margin-bottom:2em;

		$padding:1em 2em;
		
		.upload-buttons {
			button {
				margin-right:1em;

				&:last-child {
					margin-right:0;
				}
			}

			svg {
				height: 10px;
				overflow: visible;
				float: left;
				margin-right: 40px;
				margin-left: -10px;
				width: 10px;
			}
		}

		.selected-files {
			.attached-file {
				width:100%;
				height: 50px;
				margin-top: 20px;
				transition: background-color $animation-cubic-short;

				.file-name {
					margin-left: 1.5em;
				}

				.remove-file {
					float: right;
				}

				.select {
					display: inline-block;
					margin-left: 3em;

					select {
						display:inline-block;
						width:100%;
						padding: 11px;
						padding-right: 4em;
					}
				}

				svg {
					height:40px;
					float:left
				}

				&:hover {
					background-color:#FAFAFA;
				}
				
			}
		}
	}

	.is_sending {
		height: 100%;
		top:0;

		.loading-spinner {
			width:50px;
			height:50px;
			margin-bottom: 0;
		}
	}
}

