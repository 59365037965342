.display-feedbacks {
	margin-top: 1.25rem;
	padding-top: 1.25rem;
	border-top: 2px solid #e4e4eb;

	.feedback {
		margin-top: 1rem;
		padding-bottom: 1rem;
		border-bottom: 1px dashed #e4e4eb;

		.permissions {
			display: flex;
			align-items: center;
			color: #707070;
			.permission {
				display: flex;
				align-items: center;
				margin-left: .85rem;

				span.icon {
					margin-right: .25rem;
					fill: #707070;
					svg {
						position: relative;
						top: 2px;
						path{		
							fill: #707070  !important;
						}
					}
				}
			}
		}

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			padding-bottom: 0;
			border-bottom: 0;
		}
	}

	.collapsible-content {
		.feedback {
			margin-left: 1rem;

			&:first-child {
				margin-top: .5rem;
			}
		}
	}

	.collapsible-trigger svg {
		top: 2px !important;
	}

}