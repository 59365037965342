.menu-item-container {
	flex: 1;

	.menu-item {
		position: relative;
		cursor: pointer;
		color: black;
		text-decoration: none;
		display: flex;
		flex-direction: column;
		padding: 0 0.8rem;
		height: 100%;
		justify-content: center;

		&.is-active {
			&:after {
				display:block;
			}
		}

		&:after {
			display: none;
			content: '';
			width: 100%;
			height: 2px;
			background-color: #0079DB;
			position: absolute;
			left: 0;
			top: 0;
			border-radius: 2px;
		}

		.menu-item-icon {
			margin-bottom: 0.3rem;
			vertical-align: middle;
			align-self: center;

			svg {
				height: 1.5rem;
				width: 1.5rem;
				vertical-align: inherit;
			}
		}
	}

	.menu-item span {
		font-size: 0.65rem;
	}
}
