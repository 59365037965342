.alert {
	border:0;
	padding:0;
	min-height:0;
	padding: 0.8rem 1.4rem;
    font-size: 0.82em;

	&.alert-info {
		padding: 1.3rem 1rem;
	}

	&.alert-news {
		color:black;
		background-color:white;
		border-radius:$border-radius; 
		border-left:1px solid #E4E4EB;
		border-right:1px solid #E4E4EB;
		border-bottom:1px solid #E4E4EB;
		font-size: 16px;
		line-height: 26px;
		display: inline-flex;
		align-items: center;
		width: 100%;
		justify-content: space-between !important;
		flex-wrap: wrap;

		&:first-child {
			border-top:1px solid #E4E4EB;
		}

		&:last-child {
			border-bottom:1px solid #E4E4EB;
		}

		.card_subtitle {
			.date {
				font-size: 14px;
			}
		}

		.card_title {
			font-size: 24px;
		}

		.news-title {
			display: inline-flex;
			margin-right: 2rem;
		}

		.alert-timestamp .from-now {
			display: flex;
			margin-left: 0 !important;
			align-items: center;
			width: max-content;
			line-height: 16px;
			margin-top: .25rem;
		}
	}

	.subtitle {
		margin-bottom: 0.3em;
		font-size: .75em;
		font-size: 1.04em;
		line-height: 16px;

		@include bp(small) {
			float:right;
		}
	}
}
