.list-container {
	.block-container {
		float:none;
		width:100%;
		margin:0;
		box-shadow:0 5px 15px rgba(0, 0, 0, 0.05);

		h2 {
			font-size:1.7rem;
			font-weight:400;
		}
	}
}
