.type-clarifications,
.type-knowledges {
	margin-bottom: 4em;
}

.type-clarifications:last-child,
.type-knowledges:last-child {
	margin-bottom: 0;
}

.type-clarifications {
	h3 {
		text-transform: uppercase;
		font-size: .7em;
		font-weight: 500;
		color: #5d5d5d;
		letter-spacing: 1px;
		margin: 0;
		padding-bottom: 1.75em;
	}

	.proficiency {
		.block {
			.b-head {
				.title {
					font-weight: 600;
					font-size: 1.1em;
				}
			}

			.b-body {
				.demand {
					.title {
						font-weight: inherit;
						color: inherit;
						font-size: 0.9em;
					}
				}
			}

			.steps-container {
				.step {
					.description {
						background-color: transparent;
					}
				}
			}
		}
	}
}

.type-knowledges {
	h3 {
		text-transform: uppercase;
		font-size: .7em;
		font-weight: 500;
		color: #5d5d5d;
		letter-spacing: 1px;
		margin: 0;
		padding-bottom: 1.75em;
	}
}

.first-paragraph-creation {
	border-top: 20px solid #F6F6F6;
}
.first-paragraph-view {
	margin-top: 2em;
}

.proficiency {
	width:100%;

	.block {
		border:$get_the_border;
		border-bottom:0;

		.b-head {
			padding: 1.45em 3em 1.45em 1em;
			background-color:white;
			position: relative;
			z-index: 2;
			border-radius:$border-radius;
			border-bottom:$border-width solid $border-color;
			color:#3A3A3A;
			transition: $animation-cubic-short;
			font-size:0.9em;
			cursor:pointer;
			display: -ms-flexbox;
			display: flex;
			flex-wrap: nowrap;
			align-content: center;
			height: 100%;

			.title {
				position: relative;
				font-size: 0.9em;
				font-weight: 400;
				width: 40%;
				padding-right: 3em;
				display: flex;
				align-items: center;
				justify-content: center;
				
				.checkbox {
					float:left;
				}
			}

			.drawer-icon {
				position: absolute;
				right: 1em;
				height: auto;
				top: 35px;
				
				svg {
					transition: all $animation-cubic-short;
				}
			}

			.step {
				font-weight: 500;
				transition: all $animation-cubic-short;

				.description {
					width:100%;
					padding: 1.25em;
					border-radius:5px;
				}
			}

			&.active {
				box-shadow: $item-shadow;
			}

			&.student-head {
				background-color: #f5f5f5;
				border-bottom:0;
			}
		}

		.b-body {
			background-color: #f5f5f5;
			border-top:0;
			position: relative;
			z-index:1;
			border-bottom-left-radius:$border-radius;
			border-bottom-right-radius:$border-radius;
			transition: all $animation-cubic-short;
			max-height:0px;
			overflow:hidden;

			.demand {
				padding-bottom:0.85em;
				margin-bottom:0.85em;
				border-bottom:$get_the_border;
				color: #3A3A3A;
				font-size: 0.9em;
				display: flex;

				.title {
					font-weight: 600;
					font-size: 1.1em;
					max-width: 31%;
					width: 100%;
					float: left;
					padding-right: 3em;
					position: relative;
					top: 50%;
					transform: translateY(-50%);
				}

				.step {
					padding:1.25em;
		
					&:first-child {
						margin-left:0;
					}
				}

				&:last-child {
					border-bottom:0;
				}

				.action {
					margin-right:0.50em;

					&:last-child {
						margin-right:0;
					}
				}

				svg {
					height:27px;
					cursor:pointer;
				}
			}

			.loading-content {
				width:100%;
				text-align:center;
				padding:2em 0;
				margin:0 auto;

				.loading-spinner {
					margin-top: 1em;
				}
			}

			.drawer {
				margin:0em 1em;
				padding:1em 0;
				border-top:$get_the_border;
				margin-top: 0;

				textarea {
					height:70px;
					font-size: 0.9em;
					padding: 0.7em;
				}

				.add-new {
					.button-target {
						width:100%;
						float:none;
						text-align:center;
						border-radius:0;
					}
				}


				.step {
					padding: 0 !important;

					.form-row {
						padding-right: 0 !important;
					}

					&:first-child {
						margin-left: 0 !important;
					}
				}
				
			}

			.content {		
				padding:1.25em 1em;
				padding-bottom:0;
				width:100%;				
			}

			&.active {
				border-bottom:$get_the_border;
				max-height:9999999px;
			}

			&.student-view {
				background-color:white;

				.step {
					padding: 0;
					border-radius: 3px;
				}

				.demand {
					border:0;
					padding-top: 0.5em;

					&:first-child {
						padding-top:0;
					}
				}
			}
		}

		.not-showing {
			display:none !important;
		}

		.steps-container {
			width: 100%;
			display: flex;

			.step {
				font-weight: 400;
				margin-left: .625rem;
				font-size: 0.9em;
				width: 100%;

				.description {
					transition: background-color 0.24s cubic-bezier(0.215, 0.61, 0.355, 1), color 0.24s cubic-bezier(0.215, 0.61, 0.355, 1);
				}

				&:last-child {
					margin-right:0; 
				}

				&:first-child {
					margin-left:0;
				}

				&:hover {

					&.grade-F {
						.description {
							background-color: rgba( $color-grade-f, .2 );
						}
					}

					&.grade-E {
						.description {
							background-color: rgba( $color-grade-e, .2 );
						}
					}

					&.grade-C {
						.description {
							background-color:rgba( $color-grade-c, .2 );
						}
					}

					&.grade-A {
						.description {
							background-color:rgba( $color-grade-a, .2 );
						}
					}

				}


				&.checked {
					.description {
						color: white;
					}

					&.grade-F {
						.description {
							background-color:$color-grade-f;
						}
					}

					&.grade-E {
						.description {
							background-color:$color-grade-e;
						}
					}

					&.grade-C {
						.description {
							background-color:$color-grade-c;
						}
					}

					&.grade-A {
						.description {
							background-color:$color-grade-a;
						}
					}

				}

				.description {
					width: 100%;
					background-color: rgb(246, 246, 246);
      				border-radius: $border-radius;
				}
			}
		}

		.knowledge-toggle-button {
			background-color: #f5f5f5;
			border-bottom: $get_the_border;
			border-bottom-left-radius: $border-radius;
			border-bottom-right-radius: $border-radius;
			text-transform: uppercase;
			text-align:center;
			color:#3A3A3A;
			font-size:0.8em; 
			padding: 1.25em 0;
			cursor:pointer;
			font-weight: 500;
		}
	}

	.assignment-steps {
		display:block !important;
		width: 100%;

		.graded-step {
			padding:0.6em 0.8em;
			clear:both;
			color:white;
			border-radius: 5px;
			display: inline-block;
			margin-top:0.5em;
			font-weight: 400;
			font-size:0.85em;

			.type {
				width: 22px;
				height: 22px;
				line-height: 15px;
				letter-spacing:1px;
				text-align: center;
				font-size: 0.6em;
				text-transform: uppercase;
				margin-right: 0.6em;
			}

			&.grade-F {
				background-color:$color-grade-f;
			}

			&.grade-E {
				background-color:$color-grade-e;
			}

			&.grade-C {
				background-color:$color-grade-c;
			}

			&.grade-A {
				background-color:$color-grade-a;
			}
		}

		&.push-right {
			float:right;
			width:auto;
		}
	}
}

/* Body color of the table should be white if the component is placed inside the expandable component */
.expandable-content {
	.b-body {
		background-color:white !important;
	}
}
