.conversation {
	$border-radius: 5px;
	$box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
	$message-avatar-offset: 0.55rem;
	$message-avatar-size: 2.85rem;
	$mobile--message-avatar-offset: 0.35rem;
	$mobile--message-avatar-size: 1.85rem;

	// Elements
	.conversation-list {
		padding: 1.25rem;
		padding-top: 1.75rem;

		.conversation-container {
			display: block;
			border-radius: 2px;
			padding: 0.8rem;
			color: var(--color--regular);
			text-decoration: none;
			border-bottom: 1px solid #f0f0f0;

			&:hover {
				background-color: #EFF7FF;
			}

			.avatar {
				height: 2.5rem;
				width: 2.5rem;
				min-height: 2.5rem;
				min-width: 2.5rem;
				border-radius: 2.5rem;
				font-size: 0.85rem;

				@media (min-width: 550px) {
					height: 3rem;
					width: 3rem;
					border-radius: 3rem;
					font-size: 1.25rem;
				}
			}

			.latest-message {
				margin-top: 0.55rem;
				font-size: var(--size-14);
				display: flex;
				align-items: center;
			}
		}

		.search-container {
			margin-bottom: 1.25rem;
		}

		// Datalist component
		.cbp-tm-menu {
			display: none;

			@media (min-width: 550px) {
				display: block;
			}
		}

		.space--between {
			display: block;

			@media (min-width: 655px) {
				display: flex;
			}
		}

		.button {
			margin-left: .35rem !important;
		}

		.unread-badge {
			display: inline-flex;
			width: 7px;
			height: 7px;
			border-radius: 7px;
			background-color: #0079DB;
			margin-right: 7px;
		}

		.archived-conversations {
			margin-top: 1rem;
		}

		.participants {
			.person {
				margin-right: .95rem;
			}
		}
	}


	// Containers
	&.conversation-header {
		display: flex;
		align-items: center;
		//border-bottom: 1px solid #dbdbdb;
		flex-wrap: wrap;

		.name-container {
			display: flex;
			width: 100%;
			padding: 0.65rem;
			align-items: center;
		}

		.back {
			flex-grow: 0;
			margin-right: 0.65rem;

			.cl-container {
				width: 1rem;
				height: 1rem;
				left: none;
				margin: 0;
			}

			svg {
				width: 1rem;
				height: 1rem;
				left: -4px;
				top: 1px;
				position: relative;
			}
		}

		.button {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			padding: 1rem;
			background-color: none;
			border: 0;
			font-size: 0.65rem;
			letter-spacing: normal;

			&:hover {
				background-color: #e7e7e6;
			}
		}

		.person-wrap {
			margin-right: 1.35rem;
			display: inline-block;

			&:last-child {
				margin-right: 0;
			}
		}

		.meta-container {
			flex: 1;
			text-align: left;
			flex-basis: 100%;
			background-color: #f5f5f5;
			padding: 0.65rem;

			svg {
				width: 17px;
				height: 17px;
				margin-right: 0.35rem;
				position: relative;
				top: 2px;
			}

			a {
				display: inline-flex;
				color: rgba(15, 15, 15, 0.75);
				text-decoration: none;
				font-size: 0.85rem;
				margin-bottom: 0.55rem;
				align-items: center;

				&:last-child {
					margin-bottom: 0;
				}

				&:hover {
					text-decoration: underline;
					color: rgba(15, 15, 15, 1);
				}
			}
		}
	}

	&.participant-picker {
		position: relative;
		margin-top: -2rem;
		padding: 0 1.75rem;

		.radio-container {
			display: inline-block;
		}

		.form {
			display: block;
			margin-top: 1rem;
		}

		.picker-title {
			font-size: 1.45rem;
			font-weight: 500;
			padding-left: 1.25rem;
			margin-bottom: 1rem;
		}

		.subtitle {
			display: block;
			font-size: 0.9rem;
			margin-top: 1rem;
			margin-bottom: 0.35rem;
		}

		.checkbox,
		.radiobutton {
			cursor: pointer;
		}

		.contact-list {
			.contact {
				margin-bottom: .35rem;

				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}

		ul {
			padding-left: 1rem;
		}
	}

	&.conversation--wrapper {
		background-color: white;
		border-radius: $border-radius;
		box-shadow: $box-shadow;
		display: flex;
		flex-direction: column;
		overflow: hidden;

		@media (max-height: 700px) {
			min-height: 450px;
		}
	}

	&.messages-container {
		overflow: auto;
		height: 100%;
		padding: 0 1rem;

		.__inner {
			min-height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
		}

		@media (min-width: 425px) {
			padding: 0 2rem;
		}
	}

	&.message {
		position: relative;
		background-color: #f5f5f5;
		align-self: start;
		flex-grow: 0;
		border-radius: 10px;
		padding: 0.75rem;
		margin-top: 1.35rem;
		max-width: 85%;
		font-size: 0.85rem;

		.unread-badge {
			position: absolute;
			top: 50%;
			transform: translateY(-3.5px);
			left: -12px;
			width: 7px;
			height: 7px;
			border-radius: 7px;
			background-color: #0079DB;
		}

		.action-menu {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: -25px;
			font-size: 1.3rem;
			cursor: pointer;
		}

		.flag-badge {
			font-style: italic;

			.icon {
				color: #DF2D2E;
				position: relative;
				top: 2px;
				margin-right: .28rem;
			}
		}

		&.me {
			background-color: #0079DB;
			color: #fff;
			margin-left: 0;

			.unread-badge {
				right: 0;
				left: -12px;
			}

			.action-menu {
				color: rgb(54, 54, 54);
				right: 0;
				left: -25px;
			}

			.flag-badge .icon {
				color: #fff;
			}
		}

		&.narrow {
			margin-top: .5rem;
		}

		&.user {
			margin-left: 0;
			margin-right: 0;
		}

		&.no-radius-top {
			border-top-left-radius: 0;

			&.me {
				border-top-left-radius: 10px;
				border-top-right-radius: 0;
			}
		}

		&.no-radius-bottom {
			border-bottom-left-radius: 0;

			&.me {
				border-bottom-left-radius: 10px;
				border-bottom-right-radius: 0;
			}
		}

		@media (min-width: 845px) {
			max-width: 65%;
		}

		@media (min-width: 425px) {
			font-size: 0.9rem;
			padding: 1rem;
		}

		// Message hover
		&:hover {
			.action-menu {
				display: block;
			}
		}
	}

	&.header-unread-dropdown-badge {
		display: inline-block;
		width: 7px;
		height: 7px;
		border-radius: 7px;
		background-color: #0079DB;
		margin-right: 5px;
	}

	&.message-form {
		padding: 1rem;
		padding-top: .75rem;

		@media (min-width: 425px) {
			padding: 2rem;
		}

		.input-container {
			flex: 1;
			margin-right: .55rem;
			display: flex;
			align-items: center;

			textarea {
				-webkit-appearance: text;
				-moz-appearance: text;
				appearance: text;
				resize: none;
				cursor: text;

				background-color: #EDEDED;
				width: 100%;
				color: #0F0F0F;
				border: 0;
				padding: 1.25em 2em;
				border-radius: 5px;
				font-size: 0.8rem;
				font-family: 'Open Sans', Arial;

				font-size: 16px;

				&:focus {
					-webkit-box-shadow: none;
					box-shadow: none;
					outline-style: none;
					outline: none;
				}

				&:disabled {
					cursor: not-allowed;
					opacity: 0.8;
				}
			}

			@media (min-width: 425px) {
				margin-right: 1rem;
			}
		}

		button {
			color: var(--color--blue);
			padding: 0px;
			background-color: transparent !important;
			border-radius: 0px;
			border: 0px;
			box-shadow: none;

			span.icon {
				width: auto;
				height: auto;
			}

			svg {
				margin: 0;
				width: 1.3rem;
				height: 1.3rem;
			}
		}

		.c--button {
			$spinner-size: 21px;

			.spinner-container {
				position: static;
				background-color: transparent;

				.loading-spinner {
					width: $spinner-size !important;
					height: $spinner-size !important;
				}
			}
		}
	}

	// Elements
	.message-avatar {
		display: flex;
		align-items: center;
		justify-content: center;
		align-self: center;
		overflow: hidden;
		margin-top: 1.35rem;
		margin-right: $message-avatar-offset;
		margin-left: 0;
		background: #F5F5F5;
		color: #0089C2;
		font-size: 0.95rem;
		font-weight: 300;
		height: $mobile--message-avatar-size;
		width: $mobile--message-avatar-size;
		min-height: $mobile--message-avatar-size;
		min-width: $mobile--message-avatar-size;
		border-radius: $mobile--message-avatar-size;
		position: relative;

		&.narrow {
			margin-top: .15rem;
		}

		img {
			max-width: 100%;
			max-height: 100%;
			width: auto;
			height: auto;
		}

		&:hover {

			// no tooltip on smaller devices
			@media (min-width: 425px) {
				overflow: visible;
			}
		}

		@media (min-width: 425px) {
			height: $message-avatar-size;
			width: $message-avatar-size;
			min-height: $message-avatar-size;
			min-width: $message-avatar-size;
			border-radius: $message-avatar-size;
			font-size: 1.25rem;
		}
	}

	.timestamp {
		font-size: 12px;
		margin-top: 0.2rem;

		&.left {
			float: left;
		}

		&.right {
			float: right;
		}
	}
}