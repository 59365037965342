.attached-documents {
	padding-top:1em;

	&:last-child {
		.document {
			border-bottom: $get_the_border;
			border-bottom-left-radius: $border-radius;
			border-bottom-right-radius: $border-radius;
		}
	}

	.description {
		text-transform: uppercase;
		color: $color-meta-text;
		font-size: 0.75em;
		letter-spacing: 1px;
		margin-bottom:0.85em;

		&:after {
			content: '';
			display:block;
			clear:both;
		}
	}

	.column-1, .column-2 {
		float:left;
		width: calc(100% - 70px);
	}

	.column-1 {
		@include bp(medium) {
			width: 35%;
		}
	}

	.column-2 {
		@include bp(medium) {
			width: calc(100% - 40%);
		}
	}

	.column-3 {
		float: right;
		margin-right: 15px;
	}

	.document {
		background-color:white;
		border:$get_the_border;
		border-bottom:0;
		font-size:0.8em;
		cursor: pointer;
		padding:1.25em 0;
		padding-left:1.25em;
		transition: background $animation-cubic-short;
		border-radius: $border-radius;

		.icon {
			height: 65px;
			width: 55px;
			float: left;
			margin-right:1em; 

			svg {
				height:100%;
				width: 100%;
			}

			@include bp(medium) {
				height: 40px;
				width: 35px;
			}
		}

		.name {
			float:left;
			padding-left:2.25em;
			padding:0.65em 0;
			padding-bottom:0;
			font-size: 1.25em;

			strong {
				color:black;
				font-weight:500; 
			}

			@include bp(medium) {
				padding-bottom:0.65em;
			}
		}

		.file-description {
			padding: 0.73em 0;
			color:black; 
			font-size: 1.25em;
			font-weight: 400;
		}

		&:hover {
			background-color: #f5f5f5;
		}
	}

	&:after {
		content: '';
		width: 100%;
		height: 1px;
		display: block;
		clear: both;
	}
}