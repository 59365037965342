/* Region: normal */
@font-face {
	font-family: 'Open Sans';
	src: url('../fonts/OpenSans-Regular.eot');
	src: local('Open Sans Regular'), local('OpenSans-Regular'),
		url('../fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/OpenSans-Regular.woff2') format('woff2'),
		url('../fonts/OpenSans-Regular.woff') format('woff'),
		url('../fonts/OpenSans-Regular.ttf') format('truetype'),
		url('../fonts/OpenSans-Regular.svg#OpenSans-Regular') format('svg');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('../fonts/OpenSans-SemiBold.eot');
	src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
		url('../fonts/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/OpenSans-SemiBold.woff2') format('woff2'),
		url('../fonts/OpenSans-SemiBold.woff') format('woff'),
		url('../fonts/OpenSans-SemiBold.ttf') format('truetype'),
		url('../fonts/OpenSans-SemiBold.svg#OpenSans-SemiBold') format('svg');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('../fonts/OpenSans-Bold.eot');
	src: local('Open Sans Bold'), local('OpenSans-Bold'),
		url('../fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/OpenSans-Bold.woff2') format('woff2'),
		url('../fonts/OpenSans-Bold.woff') format('woff'),
		url('../fonts/OpenSans-Bold.ttf') format('truetype'),
		url('../fonts/OpenSans-Bold.svg#OpenSans-Bold') format('svg');
	font-weight: 600;
	font-style: normal;
}