svg {
	$default-icon-fill: #1D1D1B; 

	.icon-plus {
		fill:$default-icon-fill;
	}

	.icon-minus {
		stroke:$default-icon-fill;
		stroke-width:2.0013;
		stroke-linecap:round;
		stroke-linejoin:round;
		stroke-miterlimit:10;
	}

	.icon-trash {
		fill:$default-icon-fill;
	}

	.icon-pen {
		fill:$default-icon-fill;
	}

	.icon-document {
		fill: #68B1EF;
	}

	.icon-ms-onedrive {
		fill:#C8C8D1;
	}

	.icon-ms-sharepoint {
		fill:#C8C8D1;
	}

	.icon-bell { fill:#F1BC44; }
	.icon-bell-bw { fill:$default-icon-fill; }

	.icon-calendar { fill: #EF4545; }
	.icon-calendar-bw { fill:$default-icon-fill; }

	.icon-group-1 {
		fill:#70B1E8;
	}

	.icon-group-2 {
		fill:#F9F9F9;
		stroke:#000000;
		stroke-width:1.4173;
		stroke-linecap:round;
		stroke-linejoin:round;
		stroke-miterlimit:10;
	}

	.icon-group-3 {
		fill:#F2D6BB;
	}

	.icon-group-4 {
		fill:#DFA679;
	}

	.icon-group-5 {
		fill:#594336;
	}

	.icon-group-bw-1 { 
		fill:none;
		stroke:$default-icon-fill;
		stroke-width:1.4173;
		stroke-linecap:round;
		stroke-linejoin:round;
		stroke-miterlimit:10; }
	
	.icon-group-bw-2 { fill:$default-icon-fill; }
	
	.icon-task {
		fill:#EADCC1;
	}

	.icon-task-bw { fill:$default-icon-fill; }

	.icon-stats-1 {
		fill:#60B4F0;
	}

	.icon-stats-2 {
		fill:#577BF2;
	}

	.icon-stats-3 {
		fill:#6415E2;
	}

	.icon-stats-bw { fill:$default-icon-fill; }
	
	.icon-compass-1 { fill:#F1BC44; }
	.icon-compass-2 {fill:#EADCC1;}
	.icon-compass-3 {fill:#EF4545;}
	.icon-compass-4 {fill:#FFFFFF;}
	.icon-compass-bw { fill: $default-icon-fill; }

	.icon-image {
		fill: none;
		stroke: #005da9;
	}

	.icon-image-1 {
		stroke-linecap: round;
		stroke-linejoin: round;
		stroke-miterlimit: 10;
		stroke-width: 1px;
	}

	.icon-search-1 {
		fill:#f9f9f9;
		stroke:#3a3a3a;
		stroke-linecap:round;
		stroke-linejoin:round;
		stroke-width:0.99px;
	}
}

span.chevron {
	&:before {
		display: inline;
		content: '\2039';
		color: #3A3A3A;
		font-size: 30px;
		margin-right: 0.35rem;
		font-weight: 300;
		position: relative;
		top: 2px;
	}

	&.forward {
		&:before {
			content: '\203A';
		}
	}
}
