.block--fileblock {
	.file-resource {
		display: flex;
		align-items: center;

		border-radius: 3px;
		padding: 1.2rem;
		border: 1px solid #c9c9c8;
		box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
		margin-bottom: .65rem;

		transition: all 0.24s cubic-bezier(0.215, 0.61, 0.355, 1);

		.name {
			margin-left: .55rem;
			flex: 1;
		}

		.delete-resource {
			display: none;
			cursor: pointer;
		}
	}

	.c--button {
		display: none;
	}

	&.consume {
		a.file-resource {
			display: inline-flex;
			color:#3A3A3A;
			text-decoration: none;
			margin-right: 0.75rem;

			&:last-child {
				margin-right: 0;
			}

			&:hover {
				box-shadow: 0 5px 18px rgba(0, 0, 0, 0.11);
			}
		}
	}
}