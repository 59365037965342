.mentor-goal-overview {

	.mentor-goal-category {
		margin-bottom: 2rem;

		.collapsible {
			margin-left: -1.7rem;
			margin-right: -1.7rem;

			.collapsible-trigger {
				padding: 0.5rem 1.7rem;

				span.icon {
					svg {
						position: relative;
						top: 2px;
					}
				}

				.dot-container {
					float: right;
					text-align: center;
					min-width: 26.8px;
				}

				.number {
					display: inline-block;
					color: white;
					padding: 5px;
					font-size: var(--size-12);
					background-color: var(--color--blue);
					border-radius: 26.8px;
					min-width: 26.8px;
					text-align: center;
				}

				.empty-dot {
					display: inline-block;
					height: var(--size-12);
					width: var(--size-12);
					border-radius: 50%;
					background-color: #ebebeb;
				}

				&:hover {
					text-decoration: underline;
					background-color: #f5f5f5;
				}
			}
		}

		a.linked-item {
			display: flex;
			padding: 0.75rem 1.7rem;
			color: black;
			font-size: var(--size-12);
			cursor: pointer;
			align-items: center;
			justify-content: space-between;
			text-decoration: none;

			span {
				font-size: 11px;
			}

			&:hover {
				background-color: #f5f5f5;
				text-decoration: underline;

				span {
					text-decoration: none;
				}
			}
		}

	}

}