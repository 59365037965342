.study-plan {
	.verdict-feedback-row {
		display: flex;
		padding-top: 1rem;
		flex-direction: column;

		.verdict-widget {
			order: -1;
			align-items: center;
			justify-content: flex-start;
			margin-bottom: 1rem;

			.verdict-levels {
				display: flex;
			}
		}

		@media (min-width: 925px) {
			flex-direction: row;
			justify-content: space-between;

			.verdict-widget {
				margin-bottom: 0;
				order: 1;
				justify-content: flex-end;
				width: 35%;

				.verdict-levels {
					display: none;
				}

				.level-text {
					text-align: right;
					margin-right: 0;
				}
			}
		}

		.person-container {
			flex-direction: column;
			align-items: flex-start;

			.person-children {
				margin-left: 0;
				order: -1;
				margin-bottom: 1.25rem;
			}

			@media (min-width: 925px) {
				.person-children {
					margin-left: 3.65rem;
					margin-bottom: 0;
					order: 1;
				}
			}
		}
	}

	.toggle-all {
		display: flex;
		align-items: center;
		cursor: pointer;

		span.icon {
			position: relative;
			top: 2px;
			margin-right: 0.35rem;

			svg {
				transform: rotate(-90deg);
				transition: 0.45s cubic-bezier(0.215, 0.61, 0.355, 1);
			}
		}

		&.flip {
			span.icon {
				svg {
					transform: rotate(90deg);
				}
			}
		}
	}

	.collapsible-trigger {
		span.icon {
			svg {
				width: 1.2rem !important;
				height: 1.2rem !important;
				top: 2px !important;
			}
		}

		.level-text {
			display: none;

			@media print {
				display: block;
			}
		}

		.verdict.verdict-widget {
			display: none;

			@media (min-width: 925px) {
				display: inline-flex;
			}

			@media print {
				display: inline-flex;
				margin-right: 0.75rem;
			}
		}
	}

	.collapsible.open {
		.collapsible-trigger {
			.level-text {
				display: none !important;
			}

			.verdict.verdict-widget {
				@media print {
					display: none;
				}
			}
		}
	}

	@media print {
		.checkbox {
			display: none;
		}

		.collapsible-trigger {
			span.icon {
				display: none;
			}
		}

		.list-actions {
			display: none;
		}

		.collapsible-content {
			height: 100% !important;
			overflow: visible !important;
		}

		.dot {
			.dot-content {
				display: none !important;
			}

			.text {
				margin-left: 0 !important;
				font-weight: 500;
				font-size: 1.1rem;
			}
		}

		.avatar {
			display: none;
		}

		.name {
			margin-left: 0;

			&:before {
				margin-right: .25rem;
				content: '-';
			}
		}

		.cell {
			padding: .5rem 0 !important;
		}

		.draft-badge {
			margin-left: 0.75rem;
		}
	}
}