.only-feedback-container {
	width: 100%;

	.block {
		height: 4.35rem;
		padding: 0;
		padding-top: 1.4rem;
		position: relative;

		.flex {
			width: 100%;
			padding: 0 1.7rem;
			margin-bottom: 1.4rem;

			span.title {
				margin-bottom: 0;
			}

			.inner-header {
				border-radius: 3px;
			}
		}
	}

	span.title {
		&:after {
			content: '';
			background-color: var(--color);
			height: 4.35rem;
			width: 4px;
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			border-top-left-radius: 3px;
			border-bottom-left-radius: 3px;
			z-index: 0;
		}
	}

	.permission {
		font-size: 1rem;
		font-weight: 400;

		&:not(.active) {
			color: #707070;
			fill: #707070;

			path {
				fill: #707070 !important;
			}
		}


		span.icon {
			margin-right: .25rem;

			svg {
				position: relative;
				top: 2px;
			}
		}
	}

	.trigger-container {
		float: right;
		padding-left: 0;
	}
}