.section {
	a.see-more {
		color: #878889;
		text-decoration: none;
		display: inline-block;
		font-size: 0.9rem;
		margin-left: 2rem;
		display: none;

		svg {
			width: 12px;
			margin-right: 0.1rem;
		}

		@include bp(tablet) {
			display: inline;
		}
	}

	h3 {
		margin-top:0.5rem;
		margin-bottom:0.5rem;
	}

	.button {
		font-size: 0.65rem;
	}

	&.teams-assessment {
		.container {
			margin: 0 !important;
			max-width: 100% !important;
			padding: 0 !important;
		}

		.datepickers {
			label {
				margin-top: 0;
			}
		}
	}
}

.notificationsLink {
	text-decoration: none;
}

.overview {
	.section {
		&:first-child {
			margin-top: 0;
		}

		
		.latest {
			a {
				text-decoration: none;
			}
		}
	}
}
